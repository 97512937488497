// React library components
import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';

// React custom components
import { Welcome, EntryChoose, ServerForm, NodeForm, NodeDetail, Confirm, Finish } from './DNJSteps.js';
import { DNJstates }            from './DNJStates.js';
import { DNJStateMachine }      from './DNJStateMachine.js';
import withAuth 	 	        from '.././withAuth.js';

// component DNJWizard definition
export class DNJWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: DNJstates.WELCOME,
      entryType: null,
      entries: []
    };
    this._next = this._next.bind(this);
    this._back = this._back.bind(this);
    this._saveEntry = this._saveEntry.bind(this);
    this.stateMachine = new DNJStateMachine();
  }

  _saveEntry(entry) {
    let entries = this.state.entries.concat();
    entries.push(entry);
    this.setState({
      entries: entries
    });
  }

  _next(desiredState) {
    let currentState = this.state.currentState;
    let nextState = this.stateMachine.transitionTo(currentState, desiredState);
    this.setState({
      currentState: nextState
    });
  }

  _back(desiredState) {
    let currentState = this.state.currentState;
    this.setState({
      currentState: this.stateMachine.transitionFrom(currentState, desiredState)
    });
  }

  /*
   * Just a note -- you'll see the _next and _back functions
   * get passed around to child components alot. This is not
   * a very good practice, and in the real-world it would be
   * better to use a library like redux to handle application
   * state.
   */
  _currentStep() {
    switch(this.state.currentState) {
      case DNJstates.WELCOME:
        return(<Welcome next={this._next}/>);
      case DNJstates.ENTRY_CHOOSE:
        return(<EntryChoose 
          back={this._back}
          next={this._next}/>);
      case DNJstates.SERVER:
        return(<ServerForm 
          saveForm={this._saveEntry}
          back={this._back}
          next={this._next} />);
      case DNJstates.NODE:
        return(<NodeForm 
          saveForm={this._saveEntry}
          back={this._back}
          next={this._next} />);
      case DNJstates.NODE_DETAIL:
       return(<NodeDetail
         back={this._back}
         next={this._next} />);
      case DNJstates.CONFIRM:
        return(<Confirm
          entries={this.state.entries}
          back={this._back}
          next={this._next} />);
      case DNJstates.FINISH:
	    console.log(JSON.stringify(this.state.entries));

		fetch('http://powercloud.parishcrest.com.au/digest_dbbuild.pl', {
			method: 'post',
			body: JSON.stringify( this.state.entries )
		});

        return(<Finish
          entries={this.state.entries}
          back={this._back}
          next={this._next} />);
      default:
        return(<Welcome next={this._next}/>);
    }
  }

  render() {
		return(
			<Container text>
				<br />
				<Header as='h2'>New Database Job</Header>
				<br />
				{this._currentStep()}
			</Container>
		);
	}
}

export default withAuth(DNJWizard);

// React Library components`
import React, { Component }		from 'react';
import { Redirect }             from 'react-router';
import { Header }				from 'semantic-ui-react';

// React custom components

// Global variables

// const gDebug = 0;

export class AppNoRoute extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            redirect: false,
            // authenticated: this.props.authenticated,
        };
    }

    componentDidMount = () => {
        this.id = setTimeout(() => this.setState({ redirect: true }), 3000);
    }

    componentWillUnmount = () => {
        clearTimeout(this.id);
    }

	render() {
		// const { authenticated } = this.state;

		return this.state.redirect ? <Redirect to="/" />
            : (
                <div>
                    <br />
                    <Header as='h2'>Page not found</Header>
                    <p>Redirecting to Home</p>
                    <br />
                </div>
              );
	}
}

export default AppNoRoute;

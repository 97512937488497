// React library components
import React, { Component } from 'react';
import { Container, Header, Form, Button, Grid, Message, Table } from 'semantic-ui-react';
import keyMirror from 'keymirror';

// React custom components
import AuthService	 			from '.././AuthService.js';
import withAuth 	 	        from '.././withAuth.js';

// Instantiate AuthService
const Auth = new AuthService();
//Testing

// Global variables
var gDebug = 1;

/*
*
*
*       VARIABLES
*
*
*/

function GridItems( { entry } ) {
    return Object.keys(entry).map( (item,i) => {
        return (
            <Table.Row key={i}><Table.Cell> { item } </Table.Cell><Table.Cell> { entry[item] } </Table.Cell></Table.Row>
        )
    });
}

// Variables for testing
const GridWizardStates = keyMirror({
  WELCOME: true,
  VERSION: true,
  BASIC: true,
  GROUPS: true,
  SCANS: true,
  FINISH: true,
  SUCCESS: true
});

// React custom components
class GridWizardStateMachine {
  constructor() {
    this.transitions = {
      [GridWizardStates.WELCOME] : [GridWizardStates.VERSION],
      [GridWizardStates.VERSION] : [GridWizardStates.BASIC],
      [GridWizardStates.BASIC] : [GridWizardStates.GROUPS],
      [GridWizardStates.GROUPS] : [GridWizardStates.SCANS],
      [GridWizardStates.SCANS] : [GridWizardStates.FINISH],
      [GridWizardStates.FINISH] : [GridWizardStates.VERSION, GridWizardStates.WELCOME, GridWizardStates.SUCCESS]
    };
  }

  _reverseObject(obj) {
    let reversed = {};
    for(const key in obj) {
      if(obj.hasOwnProperty(key)) {
        obj[key].forEach((i) => {
          if(reversed[i] === undefined) {
            reversed[i] = [key];
          } else {
            reversed[i].push(key);
          }
        });
      }
    }
    return reversed;
  }

  _checkState(available, desired) {
    if (available.includes(desired)) {
      return desired;
    } else {
      throw new Error(`Desired state: ${desired} is not available`);
    }
  }

  transitionTo(current, desired) {
    let available = this.transitions[current].concat();
    return this._checkState(available, desired);
  }

  transitionFrom(current, desired) {
    let reversed = this._reverseObject(this.transitions);
    let available = reversed[current].concat();
    return this._checkState(available, desired);
  }
} // end class WizardStateMachine

/*
*
*
*       WELCOME PAGE
*
*
*/

// React wizard steps
export class WelcomePage extends Component {

    constructor(props) {
        super(props);

        this._next = this._next.bind(this);
    }

    _next() {
        this.props.next(this.props.nextState);
    }

    render() {
        return(
            <Grid columns='equal'>
            <Grid.Row columns={1} style={{minHeight:"8em"}}>
            <Grid.Column><h3>Welcome</h3></Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1} style={{minHeight:"10em"}}>
            <Grid.Column floated='left'>
            <p>Welcome to the Grid Database Wizard. Lets get started</p>
            </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
            <Grid.Column floated='left' width={1} >
            </Grid.Column>

            <Grid.Column floated='right' width={4}>
            <Button primary onClick={this._next}>Next</Button>
            </Grid.Column>
            </Grid.Row>

            </Grid>
        );
    }
}

/*
*
*
*       VERSION PAGE
*
*
*/

export class VersionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      errors: []
    };
    this._onChange = this._onChange.bind(this);
    this._validate = this._validate.bind(this);
    this._back = this._back.bind(this);
  }

  _onChange(e, { value }) {
    this.setState({ 
      value: value,
      errors: []
    });
  }

  _validate(e) {
    e.preventDefault();
    let value = this.state.value;
    if (value === '11.1.0.7') {
      this.props.next(GridWizardStates.BASIC);
    } else if (value === 'g12.1.0.1') {
      this.props.next(GridWizardStates.BASIC);
    } else {
      this.setState({
        errors: ['Please choose a version']
      });
    }
  }

  _back() {
    this.props.back(GridWizardStates.WELCOME)
  }

  render() {
    return(

      <Grid>
	  <Grid.Row columns={1} style={{minHeight:"8em"}}>
        <Grid.Column>
            <h3>Version</h3>
            { this.state.errors.length > 0 &&
            <Message negative>
              <p>{this.state.errors.join('. ')}</p>
            </Message>
            }
        </Grid.Column>
	  </Grid.Row>

	  <Grid.Row style={{minHeight:"10em"}}>
      <Grid.Column width={5}>
        <label>Select a version</label>
      </Grid.Column>
      <Grid.Column width={5} style={{textAlign:"left"}}>
        <Form>
        <Form.Radio 
        label='Oracle Database 11.1.0.7'
        value='11.1.0.7'
        checked={this.state.value === '11.1.0.7'}
        onChange={this._onChange}/>

        <Form.Radio 
        label='Oracle Grid 12.1.0.1' 
        value='g12.1.0.1'
        checked={this.state.value === 'g12.1.0.1'}
        onChange={this._onChange}/>
        </Form>
      </Grid.Column>
	  </Grid.Row>

	  <Grid.Row columns={2}>
      <Grid.Column floated='left' width={5}>
      </Grid.Column>
      <Grid.Column floated='right' width={5}>
        <Button primary onClick={this._validate}>Next</Button>
      </Grid.Column>
	  </Grid.Row>
      </Grid>
    );
  }
}

/*
*
*
*       BASIC PAGE
*
*
*/

class BasicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      basic_inventory_location: (this.props.basic_inv ? this.props.basic_inv : "/u01/oraInventory"),
      basic_oracle_install_option: (this.props.basic_ins ? this.props.basic_ins : "HA_CONFIG"),
      scan_name: "",
      scan_port: "",
      errors: []
    }

    this._onChange = this._onChange.bind(this);
    this._validate = this._validate.bind(this);
    this._back = this._back.bind(this);
  }

  _back(e) {
    e.preventDefault();
    this.props.back(GridWizardStates.VERSION);
  }

  _onChange(e, { name, value }) {
    this.setState({
      [name]: value
    });
  }

  _validate(e) {
    e.preventDefault();
    // You can add your validation logic here

    this.props.saveForm({
      basic_inventory_location: this.state.basic_inventory_location,
      basic_oracle_install_option: this.state.basic_oracle_install_option,
    });
    this.props.next(this.props.nextState);
  }

  render() {

    return(
      <Form>
        { this.state.errors.length > 0 &&
            <Message negative>
              <p>{this.state.errors.join('. ')}</p>
            </Message>
        }

        <Grid columns="equal">

        <Grid.Row columns={1} style={{minHeight:"8em"}}>
            <Grid.Column>
            <h3>Basic</h3>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="equal" style={{minHeight:"10em"}}>
            <Form.Group>
            <Grid.Column>
              <Form.Input
                name='basic_inventory_location'
                value={this.state.basic_inventory_location}
                onChange={this._onChange}
                label='Oracle Inventory Location'
                placeholder= {this.props.basic_inv ? this.props.basic_inv : '/u01/oraInventory' } />
            </Grid.Column>

            <Grid.Column>
              <Form.Input 
                name='basic_oracle_install_option'
                value={this.state.basic_oracle_install_option}
                onChange={this._onChange}
                label='Oracle Install Option'
                placeholder='HA_CONFIG' />

            </Grid.Column>
            </Form.Group>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column floated='left' width={5}>
            <Button secondary onClick={this._back}>Back</Button>
        </Grid.Column>
        <Grid.Column floated='right' width={5}>
            <Button primary onClick={this._validate}>Next</Button>
        </Grid.Column>
        </Grid.Row>
		</Grid>
      </Form>
    );
  }
}

export const BasicdForm = (props) => {
  return(
    <BasicPage
      type='Basic'
      basic_inv={props.basic_inv}
      basic_ins={props.basic_ins}
      next={props.next}
      back={props.back}
      saveForm={props.saveForm}
      nextState={GridWizardStates.GROUPS}/>
  );
}

/*
*
*
*       GROUPS Page
*
*
*/

export class GroupsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
		groups_asm_osdba    : (this.props.groups_osdba    ? this.props.groups_osdba : "asmdba"),
		groups_asm_osoper   : (this.props.groups_osoper   ? this.props.groups_osoper : "asmoper"),
		groups_asm_osasm    : (this.props.groups_osasm    ? this.props.groups_osasm : "asmadmin"),
		errors: []
	};

    this._onChange 	= this._onChange.bind(this);
    this._validate 	= this._validate.bind(this);
	this._back 		= this._back.bind(this);
  }

  _back(e) {
    e.preventDefault();
    this.props.saveForm({
      groups_asm_osdba      : this.state.groups_asm_osdba,
      groups_asm_osoper     : this.state.groups_asm_osoper,
      groups_asm_osasm      : this.state.groups_asm_osasm,
    });
    this.props.back(GridWizardStates.BASIC);
  }

  _onChange(e, { name, value }) {
    this.setState({
      [name]: value
    });
  }

  _validate(e) {
    e.preventDefault();
    // You can add validation logic here
    this.props.saveForm({
      groups_asm_osdba      : this.state.groups_asm_osdba,
      groups_asm_osoper     : this.state.groups_asm_osoper,
      groups_asm_osasm      : this.state.groups_asm_osasm,
    });

    this.props.next(GridWizardStates.SCANS)
  }

  render() {

    return(
      <Form>
        { this.state.errors.length > 0 &&
        <Message negative>
          <p>{this.state.errors.join('. ')}</p>
        </Message>
        }
		<Grid>
		  <Grid.Row celled="true" columns={1} style={{minHeight:"8em"}}>
		  <Grid.Column>
			<h3>Groups</h3>
			<br />
		  </Grid.Column>
		  </Grid.Row>
          
		  <Grid.Row celled="true" columns={1}  style={{minHeight:"10em"}}>
		  <Grid.Column>
        <Form.Group widths='equal'>
          <Form.Input 
            name='groups_asm_osdba'
            value={this.state.groups_asm_osdba}
            onChange={this._onChange}
            label='Group ASM OSDBA' 
            />
          <Form.Input 
            name='groups_asm_osoper'
            value={this.state.groups_asm_osoper}
            onChange={this._onChange}
            label='Oracle ASM OSOPER' 
            />
		  <Form.Input 
            name='groups_asm_osasm'
            value={this.state.groups_asm_osasm}
            onChange={this._onChange}
            label='Oracle ASM OSASM' 
            />
        </Form.Group>
        </Grid.Column>
        </Grid.Row>
        
		</Grid>
        <Grid>
          <Grid.Column floated='left' width={5}>
            <Button secondary onClick={this._back}>Back</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <Button primary onClick={this._validate}>Next</Button>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

/*
*
*
*       SCANS PAGE
*
*
*/

export class ScansPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
		scan_name:  (this.props.scan_name ? this.props.scan_name : ""),
		scan_port:  (this.props.scan_port ? this.props.scan_port : ""),
		errors: []
	};
    this._back = this._back.bind(this);
    this._onChange = this._onChange.bind(this);
    this._validate = this._validate.bind(this);
  }

  _back(e) {
    e.preventDefault();
    this.props.saveForm({
      scan_name: this.state.scan_name,
      scan_port: this.state.scan_port,
    });
    this.props.back(GridWizardStates.GROUPS);
  }

  _onChange(e, { name, value }) {
    this.setState({
      [name]: value
    });
  }

  _validate(e) {
    e.preventDefault();
    // You can add validation logic here
    this.props.saveForm({
      scan_name: this.state.scan_name,
      scan_port: this.state.scan_port,
    });

    this.props.saveEntry();

    this.props.next(GridWizardStates.FINISH)
  }

  render() {

    return(
      <Form>
        { this.state.errors.length > 0 &&
        <Message negative>
          <p>{this.state.errors.join('. ')}</p>
        </Message>
        }
		<Grid>
		  <Grid.Row celled="true" columns={1}  style={{minHeight:"8em"}}>
		  <Grid.Column>
			<h3>Scan Listeners and Services</h3>
			<br />
		  </Grid.Column>
		  </Grid.Row>

		  <Grid.Row celled="true" columns={1}  style={{minHeight:"10em"}}>
		  <Grid.Column>
        <Form.Group widths='equal'>
          <Form.Input 
            name='scan_name'
            value={this.state.scan_name}
            onChange={this._onChange}
            label='Scan Name' 
            />
          <Form.Input 
            name='scan_port'
            value={this.state.scan_port}
            onChange={this._onChange}
            label='Scan Port' 
            />
        </Form.Group>
        </Grid.Column>
        </Grid.Row>

		</Grid>

        <Grid>
          <Grid.Column floated='left' width={5}>
            <Button secondary onClick={this._back}>Back</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <Button primary onClick={this._validate}>Finalise</Button>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

export class FinishPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this._startagain = this._startagain.bind(this);
        this._finished = this._finished.bind(this);
    }

    _startagain() {
        this.props.startingState();
        if (gDebug) {
            console.log("INFO (GridWizard.js): props;");
            console.log(this.props.entries);
        }
        this.props.next(GridWizardStates.VERSION)
    }

    _finished() {
        if (gDebug) {
            console.log("INFO (GridWizard.js)[FinishPage] _finished: props;");
            console.log(this.props.entries);
        }

        Auth.fetch('https://powercloudapi.parishcrest.com.au/v1/gridwizard', {
			method: 'post',
			body: JSON.stringify( this.props.entries )
		});
        this.props.next(GridWizardStates.SUCCESS);
    }

    _back(e) {
        e.preventDefault();
        this.props.back(GridWizardStates.GROUPS);
    }

  render() {
    /*
     * Here is our final step. In the real world, we would
     * obviously do something more complicated than a javascript
     * alert
     */
	// console.log(JSON.stringify(i));

    var entries = this.props.entries;
    if (gDebug) { console.log("INFO (GridWziard.js)[render]: entries object"); console.log(entries); }

    return(

    <Grid>
        <Grid.Row celled="true" columns={1}>
            <Grid.Column>
                <h3>Summary</h3>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{minHeight:"10em"}}>
            <Grid.Column columns="equal">
                    <div className="ui segment placeholder" stretched="true">
                    <Table unstackable>
                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell>Parameter</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <tbody>
                    {entries.map((items,i) => (
                        <GridItems key={i} entry={items}/>
                    ))}
                    </tbody>
                    </Table>
                    </div>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row column={2}>
          <Grid.Column floated='left' width={4}>
            <Button onClick={this._startagain}>Start again</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={8}>
            <Button primary onClick={this._finished}>Start Job</Button>
          </Grid.Column>
        </Grid.Row>

      </Grid>
    );
  }
}

export class SuccessPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

  render() {
    if (gDebug) { console.log("INFO (GridWziard.js)[SuccessPage]render: FUNCTION CALLED"); }

    return(

    <Grid>
        <Grid.Row celled="true" columns={1}>
            <Grid.Column>
                <h3>Job status</h3>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{minHeight:"10em"}}>
            <Grid.Column columns="equal">
                    <div className="ui segment placeholder" stretched="true">
                    SUCCESS ...
                    </div>
            </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export class GridWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
        currentState: GridWizardStates.WELCOME,
        entryType: null,
        entries: [],
        basic_inventory_location:       null,
        basic_oracle_install_option:    null,
   		groups_asm_osdba:               null,
		groups_asm_osoper:              null,
		groups_asm_osasm:               null,
        scan_name:                      null,
        scan_port:                      null
    };

    this.setBasicData       = this.setBasicData.bind(this);
    this.setGroupsData      = this.setGroupsData.bind(this);
    this.setScanData        = this.setScanData.bind(this);

    this._next          = this._next.bind(this);
    this._back          = this._back.bind(this);
    this._saveEntry     = this._saveEntry.bind(this);
    this._clearState    = this._clearState.bind(this);

    this.stateMachine   = new GridWizardStateMachine();
  }

  _saveEntry(entry) {
    let entries = this.state.entries.concat();

    var new_entry = {
        "basic_inventory_location":     this.state.basic_inventory_location,
        "basic_oracle_install_option":  this.state.basic_oracle_install_option,
   		"groups_asm_osdba":             this.state.groups_asm_osdba,
		"groups_asm_osoper":            this.state.groups_asm_osoper,
		"groups_asm_osasm":             this.state.groups_asm_osasm,
        "scan_name":                    this.state.scan_name,
        "scan_port":                    this.state.scan_port
    };

    entries.push(new_entry);

    this.setState({
      entries: entries
    });
  }

  _clearState() {

    var newEntryData = {
        basic_inventory_location    : "/u01/oraInventory",
        basic_oracle_install_option : "HA_CONFIG",
        groups_asm_osdba            : "asmdba",
        groups_asm_osoper           : "asmoper",
        groups_asm_osasm            : "asmadmin",
        scan_name                   : "",
        scan_port                   : "",
    };

    var new_entries                     = [];

    this.setState({
        basic_inventory_location    : newEntryData.basic_inventory_location,
        basic_oracle_install_option : newEntryData.basic_oracle_install_option,
        groups_asm_osdba            : newEntryData.groups_asm_osdba,
        groups_asm_osoper           : newEntryData.groups_asm_osoper,
        groups_asm_osasm            : newEntryData.groups_asm_osasm,
        scan_name                   : newEntryData.scan_name,
        scan_port                   : newEntryData.scan_port,
        entries                     : new_entries,
    });
  }

  _next(desiredState) {
    let currentState = this.state.currentState;
    let nextState = this.stateMachine.transitionTo(currentState, desiredState);
    this.setState({
      currentState: nextState
    });
  }

  _back(desiredState) {
    let currentState = this.state.currentState;
    this.setState({
      currentState: this.stateMachine.transitionFrom(currentState, desiredState)
    });
  }

  // Callbacks for updating state
  setBasicData(basicData) {
    if (gDebug) { console.log("INFO (GridWizard.js)[setBasicData]: groupsData=" + JSON.stringify(basicData)); }
        this.setState({
            basic_inventory_location: basicData.basic_inventory_location,
            basic_oracle_install_option: basicData.basic_oracle_install_option
        });

        if (gDebug) { console.log("INFO (GridWizard.js)[setBasicData]: this.state=" + JSON.stringify(this.state)); }
    }

    setGroupsData(groupsData) {
        if (gDebug) { console.log("INFO (GridWizard.js)[setGroupsData]: groupsData=" + JSON.stringify(groupsData)); }

        this.setState({
            groups_asm_osdba:     groupsData.groups_asm_osdba,
            groups_asm_osoper:    groupsData.groups_asm_osoper,
            groups_asm_osasm:     groupsData.groups_asm_osasm
        });

        if (gDebug) { console.log("INFO (GridWizard.js)[setGroupsData]: this.state=" + JSON.stringify(this.state)); }
    }

    setScanData(scanData) {
        if (gDebug) { console.log("INFO (GridWizard.js)[setScanData]: scanData=" + JSON.stringify(scanData)); }

        this.setState({
            scan_name: scanData.scan_name,
            scan_port: scanData.scan_port
        });

        if (gDebug) { console.log("INFO (GridWizard.js)[setScanData]: this.state=" + JSON.stringify(this.state)); }
    }

  /*
   * Just a note -- you'll see the _next and _back functions
   * get passed around to child components a lot. This is not
   * a very good practice, and in the real-world it would be
   * better to use a library like redux to handle application
   * state.
   */

  _currentStep() {

    switch(this.state.currentState) {
      case GridWizardStates.WELCOME:
        return(<WelcomePage
            next={this._next} 
            nextState={GridWizardStates.VERSION}
            />
        );
      case GridWizardStates.VERSION:
        return(<VersionPage 
          back={this._back}
          next={this._next} 
          startingState={this._clearState}
          nextState={GridWizardStates.BASIC} />);
      case GridWizardStates.BASIC:
        return(<BasicPage 
          saveForm={this.setBasicData}
          basic_inv={this.state.basic_inventory_location}
          basic_ins={this.state.basic_oracle_install_option}
          back={this._back}
          next={this._next}
          nextState={GridWizardStates.GROUPS} />);
      case GridWizardStates.GROUPS:
        return(<GroupsPage
          saveForm={this.setGroupsData}
          groups_osdba={this.state.groups_asm_osdba}
          groups_osoper={this.state.groups_asm_osoper}
          groups_osasm={this.state.groups_asm_osasm}
          back={this._back}
          next={this._next}
          nextState={GridWizardStates.SCANS} />);
      case GridWizardStates.SCANS:
       return(<ScansPage
         saveForm={this.setScanData}
         saveEntry={this._saveEntry}
         scan_name={this.state.scan_name}
         scan_port={this.state.scan_port}
         back={this._back}
         next={this._next} />);
      case GridWizardStates.FINISH:
	    if (gDebug) {
            console.log("INFO (GridWizard)[currentStep]: entries in JSON;");
            console.log(JSON.stringify(this.state.entries));
            console.log("INFO (GridWizard)[currentStep]: Props;");
            console.log(JSON.stringify(this.props));
        }
        return(<FinishPage
          entries={this.state.entries}
          saveForm={this._saveEntry}
          startingState={this._clearState}
          auth={this.props.auth}
          back={this._back}
          next={this._next} />);
      case GridWizardStates.SUCCESS:
	    if (gDebug) {
            console.log("INFO (GridWizard)[currentStep]: SUCCESS");
        }

        return(<SuccessPage/>);

      default:
        return(<WelcomePage next={this._next}/>);
    }
  }

  render() {
		return(
			<Container text>
				<br />
				<Header as='h2'>Wizard - New Grid Database job</Header>
				<br />
				{this._currentStep()}
			</Container>
		);
	}
}

export default withAuth(GridWizard);

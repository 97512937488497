// React library components
import React, { Component }							from 'react';
import { Container, Header, Table }	from 'semantic-ui-react';

// Custom React components
import DebugSampleItem								from './DebugSampleItem';
import DebugSampleDygraph							from './DebugSampleDygraph';
import AuthService									from './AuthService';
import { AgentTableRow }							from "./AgentTableRow.js";

import withAuth 	 	                            from './withAuth.js';

const auth = new AuthService();

// Functional components
/* const Roles = ({roles}) => {
	<div>
	
	</div>
} */

// Component global variables
const gHosts = [
	{
		id : 0,
		client: "PCS",
		hostname : "ORA1",
		active : 1,
	},
	{
		id : 1,
		client: "PCS",
		hostname : "ORA2",
		active : 1,
	},
	{
		id : 2,
		client: "PCS",
		hostname : "ORA3",
		active : "Provisioned",
	},
	{
		id : 3,
		client: "WW",
		hostname : "WRO-GISDB",
		active : "1",
	},
	{
		id : 4,
		client: "WW",
		hostname : "WRO-GISDB-T1",
		active : "1",
	}
];


const gHostsJSON = [
    {
        "client_id": 1050,
        "iterator": 1,
        "name": "ORA1",
        "status": "Provisioned"
    }, {
        "client_id": 1050,
        "iterator": 2,
        "name": "ORA2",
        "status": "Provisioned"
    }, {
        "client_id": 1050,
        "iterator": 3,
        "name": "ORA3",
        "status": "Inactive"
    }, {
        "client_id": 1050,
        "iterator": 4,
        "name": "GRID1",
        "status": "Active"
    }, {
        "client_id": 1050,
        "iterator": 5,
        "name": "GRID2",
        "status": "Active"
    }
];

const user_details = [
	{
		client_name		: "Parish Crest Solutions",
		client_id		: "1",
	},
	{
		client_name		: "Western Water",
		client_id		: "2",
	}
];

const user_roles = [
	{
		key			: 1,
		role_name 	: "readonly",
		active		: 0,
	},
	{
		key			: 2,
		role_name 	: "user",
		active		: 0,
	},
	{
		key			: 3,
		role_name 	: "admin",
		active		: 0,
	},
	{
		key			: 4,
		role_name 	: "superadmin",
		active		: 1,
	}
];

// Class definition
export class Sample extends Component {

	// ** Class constructor **
	constructor (props) {
		super(props);
        
        this.state = {
            gHostsItems: gHosts.slice(0, 5),
            itemsJSON: gHostsJSON.slice(0, 4),
        }
        
		this.addDevice = this.addDevice.bind(this);		
	}

	// ** Class functions **
	// addDevice handler
	addDevice() {
		let path = '/device_add';
		this.props.history.push(path);
	}

	// render function
	render() {
		//console.log('INFO (DebugSample.js)[render]: ' + auth.getProfile());
		const user_profile = auth.getProfile();
		const { gHostsItems } = this.state;
        const { itemsJSON } = this.state;

		// var currentLocation = this.context.router.getCurrentPathname();
		// console.log('INFO (Sample.js)[render]: currentLocation = ' + currentLocation);

		return (
			<div>
			<form>
				<Container text>
					<Header as='h2'>Debug Sample</Header>
					<Header as='h5'>User details</Header>

					<div align="left">
					user_profile.username
					<ul>
						<li>
						{ 
							auth.isLoggedIn() ? user_profile.username : "Not logged in"
						}
						</li>
					</ul>
					</div>
					<div className="ui divider"></div>

					<Header as='h5'>Associated Clients</Header>
					<div align="left">
					user_details.client_name
					<ul>
					{
						user_details.map(item =>
							<li key={item.client_id}>{item.client_name}, {item.client_id}</li>
						)
					}
					</ul>
					</div>
					<div className="ui divider"></div>

					<Header as='h5'>Associated Roles</Header>
					<div align="left">
						user_roles.map
						<ul>
						{
							user_roles.map(item =>
							<li key={item.key}>{item.role_name}, {item.active}</li>
							)
						}
						</ul>
					</div>

					<div className="ui divider"></div>
					<Header as='h5'>Agents</Header>
					<Table unstackable>
						<Table.Header>
							<Table.Row>
							<Table.HeaderCell>Client</Table.HeaderCell>
							<Table.HeaderCell>Hostname</Table.HeaderCell>
							<Table.HeaderCell>Active</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
					<tbody>
						{gHostsItems.map(item => (
						<DebugSampleItem key={item.id} {...item} />
						))}
					</tbody>
					</Table>

					<div className="ui divider"></div>
					<Header as='h5'>Sample Dygraph</Header>
					<DebugSampleDygraph />

                    <Header as='h5'>Static test - same data as DB</Header>
					<Table unstackable>
						<Table.Header>
							<Table.Row>
							<Table.HeaderCell>Hostname</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
					<tbody>
						{itemsJSON.map(item => (
							<AgentTableRow key={item.iterator} {...item} />
						))}
					</tbody>
					</Table>
				</Container>
			</form>
			</div>
		);
	}
}

export default withAuth(Sample);

// Recat library components
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';

// Global variables
var gDebug = 0;

// component SampleItem definition
export class DebugSampleItem extends Component {
  render() {
    const { id, client, hostname, active } = this.props;

	if (gDebug) { console.log('INFO (DebugSampleItem.js)[render]: key=' + id + ' client=' + client + ' hostname=' + hostname + ' active=' + active); }
	
    return (
      <Table.Row key={id}>
        <Table.Cell key={client}>{client}</Table.Cell>
        <Table.Cell key={hostname}>{hostname}</Table.Cell>
        <Table.Cell key={active}>{active}</Table.Cell>
      </Table.Row>
    );
  }
}

export default DebugSampleItem;

// Recat library components
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';

// Global variables
var gDebug = 1;

// component Agent definition
export class AgentTableRow extends Component {

    constructor(props) {
        super(props);

        this._onClick=this._onClick.bind(this);
    }

    _onClick = (e,value) => {
        if (gDebug) { 
            console.log("INFO AgentTableRow.js(_onClick): passed variable;");
            console.log(e.target);
        }
    }

  render() {
    const { iterator, name, status } = this.props;

	if (gDebug) { console.log('INFO AgentTableRow.js(render): key=' + iterator + ' name=' + name + ' status=' + status); }

    return (
      <Table.Row key={iterator} >
        <Table.Cell onClick= { this._onClick } >{name}</Table.Cell>
        <Table.Cell>
			{ (status==="Active")       ? <i className="circle icon agent-on">-Active</i>                       : null}
			{ (status==="Inactive")     ? <i className="circle outline icon agent-off">-Inactive</i>            : null}
			{ (status==="Provisioned")  ? <i className="circle outline icon agent-provisioned">-Provisioned</i> : null}
      { (status==="Error")        ? <i className="circle outline icon agent-error">-Error</i>             : null}
      { (status==="Decommissioned") ? <i className="circle outline icon agent-off">-Decommissioned</i>             : null}
		</Table.Cell>
      </Table.Row>
    );
  }
}

export default AgentTableRow;

// React library components
import React, { Component } from 'react';
import { Header, Form, Button, Message, Grid } from 'semantic-ui-react';

// Custom React components
import AuthService from '../AuthService.js';

const g_debug = 0;

// Class definition
export class Login extends Component {
	// ** Class variables **

	// ** Class functions **
	// constructor
	constructor(props) {
		super(props);

        if (g_debug) {
            console.info("Login.js (constructor): constructor called. props;");
            console.log(props);
        }

		this.state = {
			username: "",
			password: "",
			errors: []
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.Auth = new AuthService();
	}

	// event handler functions
    handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    } // end handleChange

	handleSubmit = (e) => {
		// e.preventDefault();
		this.props.handleClientChange("1050")

		if (g_debug) {
			console.info("Login.js (handleSubmit): props;");
			console.log(this.props);
		}

		var { username, password } = this.state;

		this.Auth.login(username, password)
			.then(res =>{
				this.setState({ state: this.state });
				if (g_debug) { console.info("Login.js (handleFormSubmit): res=" + res); }
				if (g_debug) { console.info("Login.js (handleFormSubmit): username=" + username); }
				if (g_debug) { console.info("Login.js (handleFormSubmit): roles=" + this.Auth.getProfile().roles); }

                // Get the first client returned and set that as default (later we'll implement user preferences to set the default client)
				var roles;
				var default_client;

				if (this.Auth.getProfile().roles != null) {
					roles = this.Auth.getProfile().roles.split(",")
					if (g_debug) { console.info("Login.js (handleFormSubmit): roles[0] = " + roles[0]); }
				}

				if (roles != null ) {
					default_client = roles[0].split(":");
					if (g_debug) { console.info("Login.js (handleFormSubmit): default_client[1]= " + default_client[1]); }
					//if (typeof this.props.handleClientChange === 'function') {
					this.props.handleClientChange(default_client[1]);
					// if (g_debug) { console.info("Login.js (handleFormSubmit): handleClientChange=" + default_client[1]); }
					// } else { if (g_debug) { console.info("Login.js (handleFormSubmit): it isnt a function"); } }
				}

				// redirect to the homepage or default component
                this.props.history.push('/');
				//window.location.reload();
			}).catch(err =>{
				if (g_debug) { console.error("Login.js (Login.handleFormSubmit): " + err); }
				switch ("invalid_username_or_password") {
					case "dbi_connect_cant_connect":
						this.setState({errors: ['Cannot Connect to Database']});
						break;
					case "invalid_username_or_password":
						this.setState({errors: ['Incorrect Username or Password']});
						break;
					default:
						this.setState({errors: ['Unknown error occurred']});
				};

			}).then (res => {
				if (g_debug) { console.error("Login.js (Login.handleFormSubmit): after catch"); }
			});
	} // end handleFormSubmit

	// component will mount
	componentWillMount() {
		if(this.Auth.isLoggedIn()) {
			if (g_debug) { console.info("Login.js (componentWillMount): Auth.isloggedIn=true"); }
			this.props.history.push('/');
			//this.setState(this.state);
		} else {
			if (g_debug) { console.info("Login.js (componentWillMount): Auth.isloggedIn=False"); }
		}
	}

	render() {

        return(
			<div>

				<div className="ui message">
				<Header as='h2'>Login to your account</Header>
				<br />
				<Form onSubmit = { this.handleSubmit }>
					{ this.state.errors.length > 0 &&
						<Message negative>
						<p>{this.state.errors.join('. ')}</p>
						</Message>
					}
					<Form.Field>

					<Form.Input
						onChange={this.handleChange}
						name='username'
						label='Username' 
						icon='user'
						iconPosition='left'
						placeholder='someone@example.com'/>

					<Form.Input 
						name='password'
						onChange={this.handleChange}
						label='Password' 
						type="password"
						icon='lock'
						iconPosition='left'
						placeholder='password'/>

					</Form.Field>

					<Grid>
						<Grid.Column floated='right' width={5}>
							<Button primary type="submit">Submit</Button>
						</Grid.Column>
					</Grid>
				</Form>
				</div>
			</div>
		);
	}
}

export default Login;

// React library components
import React, { Component }     from 'react';
import { NavLink }              from 'react-router-dom';
import { Container,
            Header,
            Form,
            Button,
            Grid,
            Message,
            Table }             from 'semantic-ui-react';
import keyMirror                from 'keymirror';

// Other libraries
import uuid from "uuid";

// React custom components
import AuthService	 			from '.././AuthService.js';
import withAuth 	 	        from '.././withAuth.js';

// Instantiate AuthService
const Auth = new AuthService();

// Global variables
var gDebug = 0;

/*
*
*
*       VARIABLES
*
*
*/

const AgentAddStates = keyMirror({
  WELCOME:              true,
  PROVISIONING_DETAILS: true,
  SUMMARY:              true,
  PROVISION:            true
});

export class AgentAddStateMachine {
  constructor() {
    this.transitions = {
      [AgentAddStates.WELCOME]              : [AgentAddStates.PROVISIONING_DETAILS],
      [AgentAddStates.PROVISIONING_DETAILS] : [AgentAddStates.SUMMARY],
      [AgentAddStates.SUMMARY]              : [AgentAddStates.PROVISION, AgentAddStates.PROVISIONING_DETAILS]
    };
  }

  _reverseObject(obj) {
    let reversed = {};
    for(const key in obj) {
      if(obj.hasOwnProperty(key)) {
        obj[key].forEach((i) => {
          if(reversed[i] === undefined) {
            reversed[i] = [key];
          } else {
            reversed[i].push(key);
          }
        });
      }
    }
    return reversed;
  }

  _checkState(available, desired) {
    if (available.includes(desired)) {
      return desired;
    } else {
      throw new Error(`Desired state: ${desired} is not available`);
    }
  }

  transitionTo(current, desired) {
    let available = this.transitions[current].concat();
    return this._checkState(available, desired);
  }

  transitionFrom(current, desired) {
    let reversed = this._reverseObject(this.transitions);
    let available = reversed[current].concat();
    return this._checkState(available, desired);
  }
}

function GridItems( { entry } ) {
    return Object.keys(entry).map( (item,i) => {
        return (
            <Table.Row key={i}><Table.Cell> { item } </Table.Cell><Table.Cell> { entry[item] } </Table.Cell></Table.Row>
        )
    });
}

/*
*
*
*       WELCOME PAGE
*
*
*/

export class WelcomePage extends Component {
    constructor(props) {
        super(props);
        this._next = this._next.bind(this);
    }

    _next() {
        this.props.next(this.props.nextState);
    }

    render() {
        return(
            <Grid columns='equal'>
            <Grid.Row columns={1} style={{minHeight:"8em"}}>
            <Grid.Column><h3>Welcome</h3></Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1} style={{minHeight:"10em"}}>
            <Grid.Column floated='left'>
            <p>Welcome to the provisioning Agent Wizard. Lets get started</p>
            </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
            <Grid.Column floated='left' width={1} >
            </Grid.Column>

            <Grid.Column floated='right' width={4}>
            <Button primary onClick={this._next}>Next</Button>
            </Grid.Column>
            </Grid.Row>

            </Grid>
        );
    }
}

/*
*
*
*       AGENT PROVISIONING DETAILS PAGE
*
*
*/

class ProvisioningDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: uuid.v1(),
      name: "",
      errors: []
    }

    this._onChange = this._onChange.bind(this);
    this._validate = this._validate.bind(this);
    this._back = this._back.bind(this);
  }

  _back(e) {
    e.preventDefault();
    this.props.back(this.props.previousState);
  }

  _onChange(e, { name, value }) {
    const filteredInput = value.replace(/[^a-z0-9]/gi,'');
    this.setState({
      [name]: filteredInput.toUpperCase()
    });
  }

  _validate(e) {
    e.preventDefault();
    // You can add your validation logic here

    if (this.state.name.length < 4) {
        this.setState({
            errors: ['Provisioned name needs to be more than 3 characters']
        });
    } else {
        this.props.saveForm({
          uuid: this.state.uuid,
          name: this.state.name
        });
        this.props.next(this.props.nextState);
    }
  }

  render() {

    return(
      <Grid columns="equal">
          <Grid.Row columns={1} style={{minHeight:"7em"}}>
            <Grid.Column>
                <h3>Agent provisioning</h3>
                { this.state.errors.length > 0 &&
                <Message negative>
                  <p>{this.state.errors.join('. ')}</p>
                </Message>
                }
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns="equal" style={{minHeight:"10em"}}>
              <Grid.Column>
                  <Form.Group>

                      <label>Provisioned Name</label>
                      <Form.Input 
                        style       ={{ textTransform:"uppercase" }}
                        name        ='name'
                        value       ={ this.state.name }
                        onChange    ={ this._onChange }
                        placeholder ='name'/>

                    </Form.Group>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
                <Grid.Column floated='left' width={5}>
                </Grid.Column>
                <Grid.Column floated='right' width={5}>
                    <Button primary onClick={this._validate}>Next</Button>
                </Grid.Column>
            </Grid.Row>

        </Grid>
    );
  }
}

/*
*
*
*       SUMMARY PAGE
*
*
*/

export class Summary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors      : []
        };

        this._validate      = this._validate.bind(this);
        this._startagain    = this._startagain.bind(this);
    }

    _startagain(e) {
        // Clear the current variables
        this.props.startingState();
        // Send the Wizard to the starting state
        this.props.next(this.props.previousState);
    }

    _validate(e) {
        // You can add validation logic here
        console.log("INFO AgentAddWizard.js (_validate): fetch agentadd called", this.props.entries, this.props.related_client_id);
        
        if (this.props.related_client_id === 0) {
                this.setState({
                    errors: ['related_client_id needs to be set (click "Start again")']
                });
        } else {

            Auth.fetch('https://powercloudapi.parishcrest.com.au/v1/agentadd', {
                method: 'post',
                body: JSON.stringify( this.props.entries )
            }).then(responseData => {
                    if (gDebug) {
                        console.log("INFO (AgentList.js)[getAgents]: fetch response;");
                        console.log(responseData.msg);
                        if (responseData.msg === "ERROR") {
                            this.setState({
                                errors: [ responseData.error ]
                            });
                        } else {
                            // Send the Wizard to the next state
                            this.props.next(this.props.nextState)
                        }
                    }
                });
        }
    }

  render() {

    let entries = this.props.entries;

    return(
    <Grid>
        <Grid.Row celled="true" columns={1}>
            <Grid.Column>
                <h3>Summary</h3>
                { this.state.errors.length > 0 &&
                <Message negative>
                  <p>{this.state.errors.join('. ')}</p>
                </Message>
                }
        </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{minHeight:"10em"}}>
            <Grid.Column columns="equal">
                    <div className="ui segment placeholder" stretched="true">
                        <Table unstackable>
                        <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell>Parameter</Table.HeaderCell>
                            <Table.HeaderCell>Value</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <tbody>
                        { entries.map((items,i) => (
                            <GridItems key={i} entry={items}/>
                        )) }
                        </tbody>
                        </Table>
                    </div>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row column={2}>
          <Grid.Column floated='left' width={4}>
            <Button onClick={this._startagain}>Start again</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={8}>
            <Button primary onClick={this._validate}>Provision</Button>
          </Grid.Column>
        </Grid.Row>

      </Grid>
    );
  }
}

export class Provision extends React.Component {
    constructor(props) {
        super(props);

        this._home = this._home.bind(this);
    }

    _home(e) {
        // You can add validation logic here
        this.props.history.push('/');
    }

    render() {
        const entries = this.props.entries;
        const uuid = entries[0].uuid;
        return (
            <Grid>
                <Grid.Row celled="true" columns={1}>
                <Grid.Column>
                <h3>Installation steps</h3>
                <h5>Linux</h5>
                <div className="ui segment placeholder" style={{ textAlign:"left" }} stretched="true">
                <b>Windows OS</b>
                <ul>
                  <li>Install Strawberry Perl</li>
                  <li>Install modules;</li>
                </ul>
                <b>Linux OS</b>
                <i>Oracle Linux 6</i>
                <ul>
                  <li>Install Perl-5.6</li>
                </ul>
                <b>Step 1</b>
                <p>To install the pcsagent Perl scripts, ensure that the following modules are installed;</p>
                <ul>
                <li> Config </li>
                <li> Getopt::Long </li>
                <li> IO::Prompter </li>
                <li> File::Path </li>
                <li> LWP::Simple </li>
                <li> Archive::Extract </li>
                </ul>
                <p># cpan install Config Getopt::Long IO::Prompter File::Path LWP::Simple Archive::Extract</p>
                <b>Step 2</b>
                <p>Copy the client certificate that will be used to connect to the PCSAgent server.</p>
                <pre>{`# echo "-----BEGIN RSA PRIVATE KEY-----
izfrNTmQLnfsLzi2Wb9xPz2Qj9fQYGgeug3N2MkDuVHwpPcgkhHkJgCQuuvT+qZI
MbS2U6wTS24SZk5RunJIUkitRKeWWMS28SLGfkDs1bBYlSPa5smAd3/q1OePi4ae
dU6YgWuDxzBAKEKVSUu6pA2HOdyQ9N4F1dI+F8w9J990zE93EgyNqZFBBa2L70h4
M7DrB0gJBWMdUMoxGnun5glLiCMo2JrHZ9RkMiallS1sHMhELx2UAlP8I1+0Mav8
iMlHGyUW8EJy0paVf09MPpceEcVwDBeX0+G4UQlO551GTFtOSRjcD8U+GkCzka9W
/SFQrSGe3Gh3SDaOw/4JEMAjWPDLiCglwh0rLIO4VwU6AxzTCuCw3d1ZxQsU6VFQ
PqHA8haOUATZIrp3886PBThVqALBk9p1Nqn51bXLh13Zy9DZIVx4Z5Ioz/EGuzgR
d68VW5wybLjYE2r6Q9nHpitSZ4ZderwjIZRes67HdxYFw8unm4Wo6kuGnb5jSSag
vwBxKzAf3Omn+J6IthTJKuDd13rKZGMcRpQQ6VstwihYt1TahQ/qfJUWPjPcU5ML
9LkgVwA8Ndi1wp1/sEPe+UlL16L6vO9jUHcueWN7+zSUOE/cDSJyMd9x/ZL8QASA
ETd5dujVIqlINL2vJKr1o4T+i0RsnpfFiqFmBKlFqww/SKzJeChdyEtpa/dJMrt2
8S86b6zEmkser+SDYgGketS2DZ4hB+vh2ujSXmS8Gkwrn+BfHMzkbtio8lWbGw0l
eM1tfdFZ6wMTLkxRhBkBK4JiMiUMvpERyPib6a2L6iXTfH+3RUDS6A==
-----END RSA PRIVATE KEY-----
-----BEGIN CERTIFICATE-----
MIICMzCCAZygAwIBAgIJALiPnVsvq8dsMA0GCSqGSIb3DQEBBQUAMFMxCzAJBgNV
BAYTAlVTMQwwCgYDVQQIEwNmb28xDDAKBgNVBAcTA2ZvbzEMMAoGA1UEChMDZm9v
MQwwCgYDVQQLEwNmb28xDDAKBgNVBAMTA2ZvbzAeFw0xMzAzMTkxNTQwMTlaFw0x
ODAzMTgxNTQwMTlaMFMxCzAJBgNVBAYTAlVTMQwwCgYDVQQIEwNmb28xDDAKBgNV
BAcTA2ZvbzEMMAoGA1UEChMDZm9vMQwwCgYDVQQLEwNmb28xDDAKBgNVBAMTA2Zv
bzCBnzANBgkqhkiG9w0BAQEFAAOBjQAwgYkCgYEAzdGfxi9CNbMf1UUcvDQh7MYB
OveIHyc0E0KIbhjK5FkCBU4CiZrbfHagaW7ZEcN0tt3EvpbOMxxc/ZQU2WN/s/wP
xph0pSfsfFsTKM4RhTWD2v4fgk+xZiKd1p0+L4hTtpwnEw0uXRVd0ki6muwV5y/P
+5FHUeldq+pgTcgzuK8CAwEAAaMPMA0wCwYDVR0PBAQDAgLkMA0GCSqGSIb3DQEB
BQUAA4GBAJiDAAtY0mQQeuxWdzLRzXmjvdSuL9GoyT3BF/jSnpxz5/58dba8pWen
v3pj4P3w5DoOso0rzkZy2jEsEitlVM2mLSbQpMM+MUVQCQoiG6W9xuCFuxSrwPIS
pAqEAuV4DNoxQKKWmhVv+J0ptMWD25Pnpxeq5sXzghfJnslJlQND
-----END CERTIFICATE----- " > client.key;`}</pre>
                <b>Step 3</b>
                <p>Set the environment variable for the agent and install the PCSagent perl scripts</p>
<pre>
{`
PCSAGENTUUID=`}{ uuid }{`
curl -E client.key https://pcsagent.parishcrest.com.au/pcsagent_install.pl | perl -
`}
</pre>
                </div>
                <h5>Windows</h5>
                <div className="ui segment placeholder" style={{ textAlign:"left" }} stretched="true">
                fun fun
                </div>

                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column floated='left' width={5}>

                </Grid.Column>
                <Grid.Column floated='right' width={5}>
                <NavLink to="/"><Button primary>Home</Button></NavLink>
                </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

// component AgentAddWizard definition
export class AgentAddWizard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentState: AgentAddStates.WELCOME,
            entries                   : [],
            uuid                      : "",
            related_client_id         : this.props.clientid,
            name                      : "",
            related_agent_status_id   : "2"
        };

        this._next = this._next.bind(this);
        this._back = this._back.bind(this);

        this._saveEntry     = this._saveEntry.bind(this);
        this._clearState    = this._clearState.bind(this);

        this.stateMachine = new AgentAddStateMachine();
    }

    _clearState() {
        var newEntryData = {
            uuid                      : "",
            related_client_id         : this.props.clientid,
            name                      : "",
            related_agent_status_id   : "2"
        };

        var new_entries               = [];

        this.setState({
            uuid                      : newEntryData.uuid,
            related_client_id         : newEntryData.related_client_id,
            name                      : newEntryData.name,
            related_agent_status_id   : "2",
            entries                   : new_entries,
        });
    }

    _saveEntry(entry) {
        let entries = this.state.entries.concat();
        entry.related_client_id         = this.state.related_client_id;
        entry.related_agent_status_id   = this.state.related_agent_status_id;

        if (gDebug) { console.log("INFO AgentAddWizard.js(_saveEntry): entry;", entry); }

        entries.push(entry);
        this.setState({
            entries: entries
        });
    }

    _next(desiredState) {
        let currentState = this.state.currentState;
        let nextState = this.stateMachine.transitionTo(currentState, desiredState);
        this.setState({
            currentState: nextState
        });
    }

    _back(desiredState) {
        let currentState = this.state.currentState;
        this.setState({
            currentState: this.stateMachine.transitionFrom(currentState, desiredState)
        });
    }

  _currentStep() {
    switch(this.state.currentState) {
      case AgentAddStates.WELCOME:
        return(<WelcomePage
            next        ={ this._next }
            nextState   ={ AgentAddStates.PROVISIONING_DETAILS }
            />);

      case AgentAddStates.PROVISIONING_DETAILS:
        return(<ProvisioningDetails 
          saveForm      ={ this._saveEntry }
          back          ={ this._back }
          next          ={ this._next }
          nextState     ={ AgentAddStates.SUMMARY }
          />);

      case AgentAddStates.SUMMARY:
        return(<Summary 
          entries           ={ this.state.entries }
          back              ={ this._back }
          next              ={ this._next }
          startingState     ={ this._clearState }
          related_client_id ={ this.state.related_client_id }
          previousState     ={ AgentAddStates.PROVISIONING_DETAILS }
          nextState         ={ AgentAddStates.PROVISION }
          />);

      case AgentAddStates.PROVISION:
        return(<Provision
          entries        ={ this.state.entries }
          back           ={ this._back }
          next           ={ this._next }
          />);
      default:
        return(<WelcomePage next={this._next}/>);
    }
  }

  render() {
		return(
			<Container text>
				<br />
				<Header as='h2'>New Agent Wizard</Header>
				<br />
				{this._currentStep()}
			</Container>
		);
	}
}

export default withAuth(AgentAddWizard);

// React library components
import React, { Component }     from 'react';
import Iframe                   from 'react-iframe';
import ReactDOM                 from 'react-dom';
// import {CollapsibleComponent, CollapsibleHead, CollapsibleContent} from 'react-collapsible-component'
import { Tab }                  from 'semantic-ui-react'

import "react-table/react-table.css";

// Custom React components
import withAuth 	 	        from './withAuth.js';
import AuthService            from './AuthService.js';

// AgentList.js Global variables
const g_auth = new AuthService();     // Instantiate AuthService

// Agent.js Global variables
var g_debug = 1;     // determine if there should be debug output

// Class definition
export class Agent extends Component {
	// ** Class variables **

	// ** Class constructor **
	constructor(props) {
		super(props);

        this.state = {
            isHostnameLoaded:   false,
            isDashboardLoaded:  false,
            agent_id:           this.props.agent_id,
			hostname:           this.getHostname(this.props.match.params.agent_id),
			dashboard:          this.getDashboard(this.props.match.params.agent_id),
            clientid:           this.props.clientid,
            activeTabIndex:     1,
            panes:              [],
            iFrameHeight:       "11000px"
        }

        this.handleTabChange    = this.handleTabChange.bind(this);
        this.getHostname        = this.getHostname.bind(this);
        this.getDashboard       = this.getDashboard.bind(this);

        if (g_debug) { console.info("(constructor): agent_id=" + this.props.agent_id) }
	}

	// ** Class functions **
    handleTabChange(e) {
        if (g_debug) { console.info("(handleTabChange): active tab index is" + e.target.value) }
        this.setState({ activeTabIndex: e.target.value });
    }

    getHostname(uuid) {
        if (g_debug) { console.info("(getHostname): Retrieve this uuid=" + uuid); }

        return g_auth.fetch('https://pcsmonapi.parishcrest.com.au/v1/gethostname/'+ uuid)
            .then(responseData => {
                if (g_debug) {
                    console.info("(getHostname): fetch response;");
                    console.info(responseData[0].hostname);
                }
                this.setState({
                    isHostnameLoaded: true,
                    hostname: responseData[0].hostname
                });
                return responseData;
            });
    }

    getDashboard(uuid) {
        if (g_debug) { console.info("(getDashboard): Retrieve this uuid=" + uuid); }

        return g_auth.fetch('https://powercloudapi.parishcrest.com.au/v1/getdashboards/'+ uuid)
            .then(response => {

            var new_panes = [];
            Object.keys(response).forEach(function(key) {
                var panehash = { menuItem: key, render: () => <Tab.Pane>
                <Iframe url={ response[key] }
                    style={{maxWidth:640, width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
                    onLoad={() => {
                        const obj = ReactDOM.findDOMNode(this);
                        console.info("(getDashboard): height:this.state.iFrameHeight=" + this.state.iFrameHeight);
                        console.info(obj);
                        if (typeof obj.contentWindow !== 'undefined'){
                            this.setState({
                                "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                            });
                        }
                    }}
                    ref="iframe"
                    height      =  {this.state.iFrameHeight}
                    width       = "100%"
                    id          = "grafana_dashboard"
                    className   = "grafana_dashboard"
                    display     = "initial"
                    position    = "relative"
                    frameBorder = "0"
                    allowFullScreen />
                </Tab.Pane> };
                new_panes.push(panehash);
            }.bind(this) );
            if (g_debug){
                console.info(new_panes);
            }
            this.setState({
                isDashboardLoaded: true,
                dashboard: response.url,
                panes: new_panes,
            });
            return response;
            });
    }

	// render function
	render() {
        const { panes } = this.state;

        return (
			<div>
                <br /><br /><br />
                <Tab panes={panes} renderActiveOnly={true}/>
			</div>
		);
	}
}

export class AgentSettings extends Component {
	// ** Class variables **

	// ** Class constructor **
	constructor(props) {
		super(props);

        this.state = {
            isLoaded: false,
            clientid: this.props.clientid,
        }
	}

	// ** Class functions **

	// render function
	render() {
        const { match: { params } } = this.props;

        if (g_debug) { console.info("AgentSettings.js(render): function called", params); }

        return (
        <div>
            <br />
            <h5>General settings</h5>
			<div className="ui segment" style={{ width: "500px", textAlign: "left", margin: "auto", align: "center" }} >
                <p><b>Name:</b> { params.name } </p>
			</div>
            <h5>Agent settings</h5>
			<div className="ui segment" style={{ width: "500px", textAlign: "left", margin: "auto", align: "center" }} >
                <p><b>Agent UUID:</b> { params.agent_id } </p>
                <p><b>Timeout:</b> { params.agent_timeout } Default: 5 </p>
			</div>
            <h5>Pcsmon preferences</h5>
			<div className="ui segment" style={{ width: "500px", textAlign: "left", margin: "auto", align: "center" }} >
            <div>
                <b>Create Jira Helpdesk ticket: </b><input name="helpdesk_ticket" type="checkbox" /> <br />
                <b>Send an email alert: </b><input name="email_alert" type="checkbox" /> <br />
                <h5>Stats collection method</h5>
                <div className="ui segment" style={{ width: "480px", textAlign: "left", margin: "auto", align: "center" }} >
                    <b>email: </b><input name="stats" type="radio" /> <br />
                    <b>  web: </b><input name="stats" type="radio" /> <br />
                </div>
                <h5>Monitored services</h5>
                <div className="ui segment" style={{ width: "480px", textAlign: "left", margin: "auto", align: "center" }} >
                    <b>FILESYSTEM.v1: </b><input name="stats" type="checkbox" />
                </div>
                <h5>Oracle services and settings</h5>
                <div className="ui segment" style={{ width: "460px", textAlign: "left", margin: "auto", align: "center" }} >
                    <b>TABLESPACE.v1: </b><input name="stats" type="checkbox" /> <br /><br />
                    <b>Oracle User: </b><input name="stats" type="textbox" value="oracle"/> <br />
                    <b>Oracle Base: </b><input name="stats" type="textbox" value="/u01/app/oracle"/> <br />
                    <b>Sid:Home</b><br />
                    <input name="stats" type="textbox" value="confluen_1:/u01/app/oracle/product/12.1.0/dbhome_1" style={{ width: "400px" }}/><br />
                    <b>Sid / Tablespaces</b><br />
                    <input name="stats" type="textbox" value="confluen_1"  style={{ width: "80px" }}/>
                    <input name="stats" type="textbox" value="CONFLUENCE,SYSAUX,SYSTEM,USERS" style={{ width: "320px" }}/>
                </div>
            </div>
			</div>
        </div>
		);
	}
}

export default withAuth(Agent);

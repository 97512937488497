// React library components
import React, { Component }             from 'react';
import { Route, withRouter, Switch }    from 'react-router-dom';
import { Container, Header, Message }   from 'semantic-ui-react';
import { Helmet }                       from 'react-helmet';

// Custom React components
import AgentList		        from './AgentList.js';
import Agent, {AgentSettings}   from './Agent.js';
import AppDefault 		        from './AppDefault';
import AppNoRoute		        from './AppNoRoute';
import AppMenu			        from './AppMenu.js';
import AuthService	 	        from './AuthService.js';
import DebugSample		        from './DebugSample.js';
import DebugAlertsMap	        from './DebugAlertsMap.js';
import DNJWizard 		        from './Wizards/DNJWizard';
import AgentAddWizard           from './Wizards/AgentAddWizard';
import GridWizard 		        from './Wizards/GridWizard';
import Login 			        from './Forms/Login';
import UsersList		        from './UsersList.js';
import UserAddWizard           from './Wizards/UserAddWizard';

// Custom Cascading Style Sheets
import '../App.css';

// Instantiate g_authService
const g_auth = new AuthService();

// Global variables
const g_debug = 0;

console.log("React version: " + React.version);

class App extends Component {

    state = {
        client_selected: 0,
        client_selected_name: "",
        error: "",
        invalidate_page: false,
        active_menu_item: "home",
    };

	// Constructor
	constructor(props) {
		super(props);

        this.props.history.push('/');

        this.handleLogout           = this.handleLogout.bind(this);
        this.handleClientChange     = this.handleClientChange.bind(this);
        this.getClientName          = this.getClientName.bind(this);
    }

	// Custom function handleLogout
	// * Triggers the user to be logged out
    handleClientChange = (client_id) => {
        if (g_debug) { console.info("(handleClientChange): client_id=" + client_id); }
        // this.getClientName(client_id);

        //if (g_debug) { console.info("(getClientName): client_id=" + client_id); }
        if (!g_auth.isLoggedIn()) {
            //if (g_debug) { console.info("(getClientName): g_auth.isLoggedIn=false"); }
            return;
        }
        //if (g_debug) { console.info("(getClientName): g_auth.isLoggedIn=true"); }

		var profile_data = g_auth.getProfile();

		var roles = profile_data.roles;
		var client_roles_array;
		var client_selected_name;

		if (roles != null) {
			client_roles_array = roles.split(",");
			client_roles_array.forEach((client_roles_row) => {
				var client_split = client_roles_row.split(":");
				if (client_split[1] === client_id) {
					client_selected_name = client_split[2];
				}
			});
		}

        //if (g_debug) { console.info("(getClientName): client_name=" + client_name); }

		//this.setState({
        //    client_name: client_name
        //});

        this.setState({
            client_selected: client_id,
            client_selected_name: client_selected_name,
            active_menu_item: "home",
        });
        // this.props.history.push('/');
        this.forceUpdate();
    }

	getClientName = (client_id) => {
        if (g_debug) { console.info("(getClientName): client_id=" + client_id); }
        if (!g_auth.isLoggedIn()) {
            if (g_debug) { console.info("(getClientName): g_auth.isLoggedIn=false"); }
            return;
        }
        if (g_debug) { console.info("(getClientName): g_auth.isLoggedIn=true"); }

		var profile_data = g_auth.getProfile();

		var roles = profile_data.roles;
		var client_roles_array;
		var { client_name } = this.state;

		if (roles != null) {
			client_roles_array = roles.split(",");
			client_roles_array.forEach((client_roles_row) => {
				var client_split = client_roles_row.split(":");
				if (client_split[1] === client_id) {
					client_name = client_split[2];
				}
			});
		}

        if (g_debug) { console.info("(getClientName): client_name=" + client_name); }

		this.setState({
            client_name: client_name
        });
    }

	handleLogout() {
		if (g_debug) { console.info("(handleLogout): calling g_auth.logout()"); }
		g_auth.logout();
        this.setState({
            client_selected: 0,
            active_menu_item: "home"            
        });
        
        // this.props.history.push('/');

        if (g_debug) { console.info("(handleLogout): client_selected=" + this.state.client_selected); }
	}

    invalidatePage = (validation_state) => {
        this.setState({
            invalidate_page: true
        });
    }

	// Custom function requireg_auth
	// * Checks if the Route should be visible if g_authenticated

	// render function
	render() {
        const { error }             = this.state;
        const { client_selected }   = this.state;
        const { active_menu_item }  = this.state;
        const { client_selected_name }       = this.state;

		if (g_debug) { console.info("(render): client_selected=" + client_selected + ", client_name=" + client_selected_name); }

		return (
			<div className="App">
                <Helmet>
                    <title>Powercloud</title>
                </Helmet>
				<div className="App-header">
					<Header inverted as="h2">Powercloud!</Header>
				</div>

				<br />

				<Container>
					<AppMenu
                        client_id={ client_selected } client_name= { client_selected_name } handleLogout={ this.handleLogout } active_menu_item={ active_menu_item }
                        handleClientChange={ (client_id) => this.handleClientChange(client_id) }
                    />

                    {error &&
                        <Message negative>ERROR: {error}</Message>
                    }

                    <Switch>
                        <Route exact path='/' component={ AppDefault } />

                        <Route path='/pcs' component={() => window.location = 'https://www.parishcrest.com.au'}/>

                        <Route
                            path="/login"
                            render={(props) => ( <Login handleClientChange={ this.handleClientChange } {...props} /> )}
                        />

                        <Route
                            path="/agents"
                            render={(props) => (<AgentList clientid={ client_selected } {...props} /> )}
                         />

                        <Route
                            path="/agent_add"
                            render={(props) => (<AgentAddWizard clientid={ client_selected } {...props} /> )}
                        />

                        <Route
                            path="/agent_display/:agent_id"
                            render={(props) => (<Agent {...props} /> )}
                         />

                        <Route
                            path="/agent_settings/:agent_id"
                            render={(props) => (<AgentSettings {...this.props} /> )}
                         />

                        <Route
                            path="/users"
                            render={(props) => (<UsersList clientid={ client_selected } {...props} /> )}
                         />
                        <Route
                            path="/user_add"
                            render={(props) => (<UserAddWizard clientid={ client_selected } {...props} /> )}
                        />

                        <Route path="/debugsample" component={ DebugSample } />

                        <Route
                            path="/debugalertsmap"
                            render={(props) => (<DebugAlertsMap {...props} /> )}
                        />

                        <Route path="/database_new" component={ DNJWizard } />

                        <Route path="/grid_database_new" component={ GridWizard } g_auth={ g_auth } router= { this }/>

                        <Route
                            render={(props) => (<AppNoRoute g_authenticated={ g_auth.isLoggedIn() } {...props} /> )}
                        />

                    </Switch>
				</Container>
			</div>
		);
	}
}

export default withRouter(App);

// React Library components`
import React, { Component }		from 'react';
import { Header, Segment }				from 'semantic-ui-react';
import { Link }					from 'react-router-dom';

// React custom components
import AuthService	 			from './AuthService.js';

// Instantiate AuthService
const g_auth = new AuthService();
const g_debug = 0;

const SegmentAuthenticated = () => (
		<div><p align="left">If you are looking for Oracle services, please visit <Link to="/pcs">Parish Crest Solutions</Link></p></div>
)

const SegmentNotAuthenticated = () => (
		<div>
			<p aligh="left">Introducing Parish Crest's remote agent system. Here, you can monitor your systems with unique metrics and execute custom jobs developed by PCS. If you are a current customer of PCS, please login with your provided username and password by clicking on, 'Login' in the menubar.</p>
			<p align="left">If you are looking for Oracle services, please visit <Link to="/pcs">Parish Crest Solutions</Link></p>
		</div>
)

export class AppDefault extends Component {
	// ** Class constructor **
	constructor(props) {
		super(props);

        this.state = {
			profile_data	: {},
			authenticated   : "false",
		}
	}

	componentDidMount() {
		if(g_debug) { console.info('(componentDidMount): function called'); }
		if (g_auth.isLoggedIn()) {
			if (g_debug) { console.info('(componentDidMount): authenticated') }
			this.setState({
				profile_data: g_auth.getProfile(),
				authenticated: "true"
			});
		}
	}

	render() {
		const { profile_data }	= this.state;
		const { authenticated }	= this.state;
		let authenticated_string;

		if (g_debug) { console.info('(render): authenticated=' + authenticated); }

		return (
			<div>
				<br /><Segment style={{height:500}}>
				<Header as='h2'>Welcome to the homepage, { authenticated === false ?
					"guest" : profile_data.first_name }
					</Header>
				
				{ authenticated ? SegmentNotAuthenticated() : SegmentAuthenticated() }
				</Segment>
			</div>
		);
	}
}

export default AppDefault;

// React library components
import React, { Component }							from 'react';
import { Button, Grid, Container, Popup, Confirm }   from 'semantic-ui-react';
import ReactTable                                   from 'react-table';

import "react-table/react-table.css";

// Custom React components
import withAuth 	 	                            from './withAuth.js';
import AuthService	 			                    from './AuthService.js';

// AgentList.js Global variables

const Auth = new AuthService();     // Instantiate AuthService

const columns = [{
    Header: 'User ID',
    accessor: 'id',
    show: false
},{
    Header: 'Full Name',
    accessor: 'name'
},{
    Header: 'Username',
    accessor: 'username'
},{
    Header: 'Login Details',
    accessor: 'logindetails',
	style: { 'white-space': 'unset', 'width':'250px' }
},{
    Header: 'Client Access',
    accessor: 'clients',
	style: { 'flex':'400 0 auto', 'white-space': 'unset','width':'300px' }
},{
    Header: 'Actions',
    Cell:   row => (
        <span>
        <Popup trigger={<i className="plus icon" name="addlogin" />} content='Add login' />
        <Popup trigger={<i className="cog icon" name="settings" />} content='User settings' />
        <Popup trigger={<i className="trash alternate outline icon" name="delete" />} content='delete User' />
        <Popup trigger={<i className="question circle outline icon" name="helpdesk" />} content='create ticket' />
        </span>
    )
}
];     // set up the columns for the user list table

var gDebug = 0;     // determine if there should be debug output

// Class definition
export class UsersList extends Component {
	// ** Class variables **

	// ** Class constructor **
	constructor(props) {
		super(props);

        this.state = {
            isLoaded        : false,
            clientid        : this.props.clientid,
            users           : this.getUsers(),
            deleteConfirm   : false,
            agent_clicked   : "",
        }

        // this.getDerivedStateFromProps   = this.getDerivedStateFromProps.bind(this);

        this.getUsers                     = this.getUsers.bind(this);
        this.handleButtonAddUser          = this.handleButtonAddUser.bind(this);
        //this.handleTableClick           = this.handleTableClick.bind(this);
        this.handleDeleteConfirmClick     = this.handleDeleteConfirmClick.bind(this);
        this.handleDeleteCancelClick      = this.handleDeleteCancelClick.bind(this);
	}

	// ** Class functions **

	// handleButtonProvisionAgent handler
	handleButtonAddUser() {
		let path = '/user_add';
		this.props.history.push(path);
	}

    handleDeleteConfirmClick = (state, rowInfo, column, instance) => {
        console.log("INFO UsersList.js(handleDeleteConfirmClick): called");
        this.setState({ deleteConfirm: false });
        Auth.fetch('https://pcsmonapi.parishcrest.com.au/v1/deleteuser/' + this.state.clientid + '/' + this.state.agent_clicked)
                     .then(responseData => {
                        if (gDebug) {
                            console.log("INFO (UsersList.js)[handleDeleteConfirmClick]: fetch response;");
                            console.log(responseData);
                        }
                        this.setState({
                            isLoaded: true,
                            users: responseData
                        });
                        return responseData;
                     });
        // let path = '/agent_delete/' + this.state.agent_clicked;
        // this.props.history.push(path);
    }

    handleDeleteCancelClick = () => {
        console.log("INFO UsersList.js(handleDeleteCancelClick): called");
        this.setState({ deleteConfirm: false });
    }
/*
    handleTableClick = (state, rowInfo, column, instance) => {

        return {
            onClick: e => {
                if (typeof e.target.attributes.name !== 'undefined') {
                    if ( e.target.attributes.name.value === "delete" ) {
                        console.log("INFO UsersList.js(handleTableClick): found delete");
                        this.setState({ deleteConfirm: true,
                                        agent_clicked: rowInfo.original.uuid});
                    } else if ( e.target.attributes.name.value === "settings" ) {
                        console.log("INFO UsersList.js(handleTableClick): not found delete");
                        let path = '/user_settings/' + rowInfo.original.uuid;
                        this.props.history.push(path);
                    }
                } else {
                    let path = '/agent_display/' + rowInfo.original.uuid;
                    this.props.history.push(path);
                }
            }
        }
    }
*/
    getUsers() {
        //let clientid = this.state.clientid;
        if (gDebug) { console.log("INFO (UsersList.js)[getUsers]: Retrieve the list of users"); }

        return Auth.fetch('https://powercloudapi.parishcrest.com.au/v1/getusers/')
                     .then(responseData => {
                        if (gDebug) {
                            console.log("INFO (UsersList.js)[getUsers]: fetch response;");
                            console.log(responseData);
                        }
                        this.setState({
                            isLoaded: true,
                            users: responseData
                        });
                        return responseData;
                     });
    }

    static getDerivedStateFromProps(props, state) {
        if (gDebug) {
            console.log("INFO (UsersList.js)[getDerivedStateFromProps]: function called props=" + props.clientid + " state=" + state.clientid);
        }

        if (props.clientid !== state.clientid) {
                if (gDebug) { console.log("INFO (UsersList.js)[getDerivedStateFromProps]: do not equate"); }
                return {
                    clientid: props.clientid,
                };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clientid !== prevProps.clientid) {
            if (gDebug) {
                console.log("INFO (UsersList.js)[componentDidUpdate]: props, prevprops;");
                console.log(prevProps.clientid);
                console.log(this.props.clientid);
            }
            this.setState({
                users: this.getUsers(this.props.clientid),
                isLoaded: false
            });
            // this.forceUpdate();
        }
    }

	// render function
	render() {
        const { users }        = this.state;
        const clientid          = this.props.clientid;
        const { isLoaded }      = this.state;

        const handleTableClick  = this.handleTableClick;

        if (gDebug) { console.log("INFO (UsersList.js)[render]: users =" + users.length);console.log(users); }
        if (gDebug) { console.log("INFO (UsersList.js)[render]: clientid =" + clientid); }
        if (gDebug) { console.log("INFO (UsersList.js)[render]: isLoaded =" + isLoaded); }

        if (users === null){
            return(
            <div>error</div>
            );
        }

        return (
			<div>
			<form>
				<Container text>
                <br />
                    { (isLoaded === false && clientid !==0) &&
                    <div>Loading</div> }

                <Confirm open={this.state.deleteConfirm} onCancel={this.handleDeleteCancelClick} onConfirm={this.handleDeleteConfirmClick} />

                { (clientid !==0 && isLoaded === true && users.length === 0 ) &&
                    <div  style={{minHeight:"8em"}}> No users available. Click the '+' button to add a new agent</div>
                }

                { ( clientid === 0 && users.length === 0 ) &&
                    <div  style={{minHeight:"8em"}}> No client is selected, please select one from the menu</div>
                }

                { (clientid > 0 && users.length !== 0 && isLoaded === true ) &&
                    <div>
                    <br />
                    <ReactTable
                        data={users}
                        columns={columns}
                        getTrProps={handleTableClick}
                        defaultPageSize= {12}
                        pageSizeOptions = {[12,24]}

                    />
                    </div>
                }
                <br />
                <Grid>
                    <Grid.Column floated='left' width={5}>
                        <Popup trigger={<Button data-content="Add User" primary onClick={this.handleButtonAddUser}>+</Button>} content='Add a user' />
                    </Grid.Column>
                    <Grid.Column floated='left' width={1}>
                    </Grid.Column>
                </Grid>

				</Container>
			</form>
			</div>
		);
	}
}

export default withAuth(UsersList);

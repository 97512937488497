// React library components
import React, { Component }				from 'react';
import IFrame                           from 'react-iframe';
import Fullscreen                       from 'react-full-screen';

// Custom React components
import withAuth 	 	                from './withAuth.js';

// Class definition
export class DebugAlertsMap extends Component {

	// ** Class constructor **
    constructor(props) {
        super(props);
        
        this.state = {
            isFull: false,
        };
    };

	// ** Class functions **
    goFullscreen = () => {
        this.setState({
            isFull: true,
        });
    }

	// render function
	render() {

        let { isFull } = this.state;

		return (
			<div>
                <button onClick={ this.goFullscreen }>
                    Fullscreen
                </button>

                <Fullscreen
                    enabled     ={ isFull }
                    onChange    ={ isFull => this.setState({isFull}) }
                >
                    <div className="full-screenable-node">
                        <IFrame url="https://pentaho.parishcrest.com.au/pentaho/api/repos/%3Ahome%3Aadmin%3AClient_Alert_Dashboard%3Aclient_alert_dashboard.wcdf/generatedContent?userid=admin&password=Dru8C1114"
                            width="100%"
                            height="100vh"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                            allowFullScreen
                        />
                    </div>
                </Fullscreen>
			</div>
		);
	}
}

export default withAuth(DebugAlertsMap);

// React library components
import React, { Component } from 'react';

// Custom React components
import AuthService from './AuthService';

export default function withAuth(AuthComponent) {
	const g_auth = new AuthService();

	return class AuthWrapped extends Component {
		constructor() {
			super();
			this.state = {
				user: null
			}
		}

		componentDidMount(){
			if (!g_auth.isLoggedIn()) {
				this.props.history.replace('/login');
			} else {
				try {
					const profile = g_auth.getProfile()
					this.setState({
						user: profile
					})
				}
				catch(err){
					g_auth.logout()
					this.props.history.replace('/');
				}
			}
		}

		render() {
			if (this.state.user) {
				return (
					<AuthComponent history={this.props.history} user={this.state.user} {...this.props} />
				)
			} else {
				return null;
			}
		}
	}; // end class AuthWrapped

} // end export default function withAuth(AuthComponent)

import keyMirror from 'keymirror';

export const DNJstates = keyMirror({
  WELCOME: true,
  ENTRY_CHOOSE: true,
  NODE: true,
  SERVER: true,
  NODE_DETAIL: true,
  CONFIRM: true,
  FINISH: true
});
// React library components
import React, { Component } from 'react';
import { Form, Button, Grid, Message, List } from 'semantic-ui-react';
import keyMirror from 'keymirror';

export const DNJstates = keyMirror({
  WELCOME: true,
  ENTRY_CHOOSE: true,
  NODE: true,
  SERVER: true,
  NODE_DETAIL: true,
  CONFIRM: true,
  FINISH: true
});

// Global variables

// React custom components

export const Welcome = (props) => {
  return(
    <Grid>
      <p>Welcome to the Wizard. Lets get started</p>
      <Grid.Column floated='left' width={5}>
      </Grid.Column>
      <Grid.Column floated='right' width={5}>
        <Button primary onClick={() => props.next(DNJstates.ENTRY_CHOOSE)}>Next</Button>
      </Grid.Column>
    </Grid>
  );
}

export class EntryChoose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      errors: []
    };
    this._onChange = this._onChange.bind(this);
    this._validate = this._validate.bind(this);
    this._back = this._back.bind(this);
  }

  _onChange(e, { value }) {
    this.setState({ 
      value: value,
      errors: []
    });
  }

  _validate(e) {
    e.preventDefault();
    let value = this.state.value;
    if (value === 'server') {
      this.props.next(DNJstates.SERVER);
    } else if (value === 'node') {
      this.props.next(DNJstates.NODE);
    } else {
      this.setState({
        errors: ['Please choose an entry type']
      });
    }
  }

  _back() {
    this.props.back(DNJstates.WELCOME)
  }

  render() {
    return(
      <Form>
        { this.state.errors.length > 0 &&
        <Message negative>
          <p>{this.state.errors.join('. ')}</p>
        </Message>
        }
        <Form.Field>
          <label>I am adding ...</label>
          <Form.Radio 
            label='a virtual machine' 
            value='node'
            checked={this.state.value === 'node'}
            onChange={this._onChange}/>
          <Form.Radio 
            label='a server' 
            value='server'
            checked={this.state.value === 'server'}
            onChange={this._onChange}/>
        </Form.Field>
        <Grid>
          <Grid.Column floated='left' width={5}>
            <Button secondary onClick={this._back}>Back</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <Button primary onClick={this._validate}>Next</Button>
          </Grid.Column>
        </Grid>
       </Form>
    );
  }
}

class BaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      hostname: "",
      domain: "",
      primary_ip: "",
      secondary_ip: "",
      ternary_ip: "",
      errors: []
    }
    this._onChange = this._onChange.bind(this);
    this._validate = this._validate.bind(this);
    this._back = this._back.bind(this);
  }

  _back(e) {
    e.preventDefault();
    this.props.back(DNJstates.ENTRY_CHOOSE);
  }

  _onChange(e, { name, value }) {
    this.setState({
      [name]: value
    });
  }

  _validate(e) {
    e.preventDefault();
    // You can add your validation logic here

    this.props.saveForm({
      type: this.props.type,
      hostname: this.state.hostname,
      domain: this.state.domain,
      primary_ip: this.state.primary_ip,
      secondary_ip: this.state.secondary_ip,
      ternary_ip: this.state.ternary_ip
    });

    this.props.next(this.props.nextState);
  }

  render() {
    return(
      <Form>
        { this.state.errors.length > 0 &&
        <Message negative>
          <p>{this.state.errors.join('. ')}</p>
        </Message>
        }
        <h2>{this.props.type} details:</h2>
        <Form.Group widths='equal'>
          <Form.Input 
            name='hostname'
            value={this.state.hostname}
            onChange={this._onChange}
            label='Hostname' 
            placeholder='Hostname'/>
          <Form.Input 
            name='domain'
            value={this.state.domain}
            onChange={this._onChange}
            label='Domain' 
            placeholder='domain'/>
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input 
            name='primary_ip'
            value={this.state.primary_ip}
            onChange={this._onChange}
            label='Primary IP' 
            placeholder='Primary IP'/>
          {this.props.type === 'node' &&
            <Form.Input 
              name='test_hidden'
              value={this.state.test_hidden}
              onChange={this._onChange}
              label='test_hidden' 
              placeholder='test_hidden'/>
          }
          <Form.Input 
            name='secondary_ip'
            value={this.state.secondary_ip}
            onChange={this._onChange}
            label='Secondary IP' 
            placeholder='Secondary IP'/>
          <Form.Input 
            name='ternary_ip'
            value={this.state.ternary_ip}
            onChange={this._onChange}
            label='Ternary IP' 
            placeholder='Ternary IP'/>
        </Form.Group>
        <h2>Oracle RAC details:</h2>
		<Form.Group>
		</Form.Group>
		<Form.Group>
		</Form.Group>
        <Grid>
          <Grid.Column floated='left' width={5}>
            <Button secondary onClick={this._back}>Back</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <Button primary onClick={this._validate}>Next</Button>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

export const NodeForm = (props) => {
  return(
    <BaseForm
      type='Node'
      next={props.next}
      back={props.back}
      saveForm={props.saveForm}
      nextState={DNJstates.NODE_DETAIL}/>
  );
}

export const ServerForm = (props) => {
  return(
    <BaseForm
      type='Server'
      next={props.next}
      back={props.back}
      saveForm={props.saveForm}
      nextState={DNJstates.CONFIRM}/>
  );
}

export class NodeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this._onChange = this._onChange.bind(this);
    this._validate = this._validate.bind(this);
  }
  _onChange(e) {

  }

  _validate(e) {
    // You can add validation logic here
    this.props.next(DNJstates.CONFIRM)
  }

  render() {
    let options = [
      {
        text: 'Yes',
        value: 'Yes'
      },
      {
        text: 'No',
        value: 'No'
      },
      {
        text: "Don't Know",
        value: "Don't Know"
      }
    ];

    return(
      <Form>
        <h2>Node History</h2>
        <Form.Field>
          <label>Has your boat been involved with piracy in the past 12 months?</label>
          <Form.Dropdown placeholder='Select Answer' options={options} />
        </Form.Field>
        <Grid>
          <Grid.Column floated='left' width={5}>
            <Button secondary onClick={() => this.props.back(DNJstates.BOAT)}>Back</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <Button primary onClick={this._validate}>Next</Button>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

export class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this._onChange = this._onChange.bind(this);
    this._validate = this._validate.bind(this);
  }
  _onChange(e) {

  }

  _validate(e) {
    // You can add validation logic here
    this.props.next(DNJstates.FINISH)
  }
  render() {
    /*
     */
	 
    return(
      <Grid>
        <Grid.Row>
          <p>Your entries:</p>
          <List>
            {this.props.entries.map((iterator) => {
              return(
                <List.Item>
                  <List.Icon name={iterator.type === 'Node' ? 'hdd' : 'server' } />
                  <List.Content>{iterator.hostname} {iterator.domain} {iterator.primary_ip} {iterator.secondary_ip} {iterator.ternary_ip}</List.Content>
                </List.Item>
              );
            })}
          </List>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column floated='left' width={5}>
            <Button onClick={() => this.props.next(DNJstates.ENTRY_CHOOSE)}>Add Another</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
			<Button primary onClick={this._validate}>Finish!</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export class Finish extends React.Component {

  render() {
    /*
     * Here is our final step. In the real world, we would
     * obviously do something more complicated than a javascript
     * alert
     */
	// console.log(JSON.stringify(i));

    return(
      <Grid>
        <Grid.Row>
          <p>Finish!</p>
          <List>
            {this.props.entries.map((iterator) => {
              return(
                <List.Item>
                  <List.Icon name={iterator.type === 'Node' ? 'hdd' : 'server' } />
                  <List.Content>{iterator.hostname} {iterator.domain} {iterator.primary_ip} {iterator.secondary_ip} {iterator.ternary_ip}</List.Content>
                </List.Item>
              );
            })}
          </List>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column floated='left' width={5}>
            <Button onClick={() => this.props.next(DNJstates.ENTRY_CHOOSE)}>Add Another</Button>
          </Grid.Column>
          <Grid.Column floated='right' width={5}>
            <Button primary onClick={this._finished}>Finish!</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
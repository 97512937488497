// Recat library components
import React, { Component }		from 'react';
import Dygraph					from 'dygraphs';

// Custom React components
import AuthService	 			from './AuthService.js';

// AgentList.js Global variables
const Auth = new AuthService();     // Instantiate AuthService

const debug = 0;

// component SampleItem definition
export class DebugSampleDygraph extends Component {

	componentDidMount() {
		const cpupct = 80;
		const valueRange = [0,100];
		var data1;
		Auth.fetch('https://pcsmonapi.parishcrest.com.au/v1/agent_display/garbage')
		//.then((response) => response.json())
		.then((responseData) => {
			const len1 = responseData.length;
			if (debug) { console.log("INFO (DebugSampleDygraph.js)[componentDidMount]: Length of data: " + len1); }
			data1 = responseData;
			var r = "reportDate,CPU Percentage,High Utilization Threshold\n";
			const len = data1.length;
			if (debug) { console.log("INFO (DebugSampleDygraph.js)[componentDidMount]:data1 length: " + len); }
			for (var i = 0; i < data1.length; i++) {
				r += data1[i].minute;
				r += "," + data1[i].cpupct;
				r += "," + cpupct;
				r += "\n";
			}
			if (debug) { console.log(r); }

			data1 = r;
			new Dygraph(document.getElementById("dynamicdiv"), data1, {
			  //title: 'Utlization Loews',

			  stackedGraph: false,
			  valueRange: valueRange,
              labelsDiv: document.getElementById("dynamiclabels"),
			  ylabel:"CPU (Percentage)",
			  xlabel:"Days (GMT)" , 
			  legend: 'always' ,
			  showRangeSelector: true,
			  drawPoints: false,
			  colors: ['#116466','#cd040b'],		  
			  fillGraph: true,
		      fillAlpha: 0.9,	
			  rangeSelectorHeight: 50,
			  rangeSelectorPlotFillColor: '#116466',
			  showRoller :false,
			  labelsSeparateLines: true,
		      rangeSelectorPlotFillGradientColor: '',
			  series: {
				  "High Utilization Threshold": {
					  fillGraph: false
				  }
			  }
			});
		}); // end fetch
	}

	render() {
		//const { agent_key, client, hostname, active } = this.props;
		//console.log('INFO (DebugSampleDygraph.js)[render]: key=' + agent_key + ' client=' + client + ' hostname=' + hostname + ' active=' + active);
		const dygraphStyle = {
			width: '100%'
		};

		return (
			<div id="dynamicdiv" style={dygraphStyle}>
			[Graph placeholder graph is loading ... ]
			<br /><br /><br /><br />
			</div>
		);
	}
}

export default DebugSampleDygraph;

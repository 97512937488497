/*
	Filename:	AuthService.js
	Reference:	HPTchBlogs, https://hptechblogs.com/using-json-web-token-react/
*/

// React library components
import * as jwt_decode from 'jwt-decode';

// React custom components
const debug = 0;

// class AuthService definition
export default class AuthService {

    // ** Class functions **
	// Constructor
    constructor(domain) {
        this.domain = domain || 'https://auth.parishcrest.com.au' // API server domain
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
		this.state = {
			errors: []
		}
   }

    login(username, password) {
        // Get a token from api server using the fetch api
		//console.log(JSON.stringify({
        //        username,
        //        password
        //    }));

		return fetch(this.domain + '/login/v3', {
			method: 'post',
			body: JSON.stringify({
                username,
                password
            })
		}).then(response => {
			if (debug) { console.info("AuthService.js (AuthService.login): response.status="); }
			if (response.status === 400) {
				if (debug) { console.info("AuthService.js (AuthService.login): response = 400"); }
				return;
			}
			if (response.status === 500) {
				if (debug) { console.info("AuthService.js (AuthService.login): response = 500"); }
				return;
			}
			return response.json();
		}).then(result => {
				//const api_token = JSON.parse(result).api_token;
				//console.info("AuthService.js): api_token = ' + api_token);
				//console.info("AuthService.js): api_token = ' + result.api_token);
				this.setToken(result.api_token);
				//console.info("AuthService.js (AuthService.login): after set token');
				return Promise.resolve(result);
			});
	}		// end login (username, password)

    isLoggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // Getting token from localstorage
		if (debug) { console.info("AuthService.js (isLoggedIn): api_token=" + token); }
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = jwt_decode(token);
			//console.info("AuthService.js)[isTokenExpired]: in funtion');
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(id_token) {
        // Saves user token to localStorage
		if (debug) {  console.info("AuthService.js(setToken): id_token = " + id_token); }
        localStorage.setItem('id_token', id_token)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
		if (debug) { console.info("AuthService.js (AuthService.setToken): logout() called"); }
        localStorage.removeItem('id_token');
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
		var decoded_token;
		try {
			decoded_token = jwt_decode(this.getToken());
			if (debug) { console.info("AuthService.js (AuthService.getProfile): token=" + this.getToken()); }
			if (debug) { console.info("AuthService.js (AuthService.getProfile): username=" + decoded_token.username); }
		} catch (e) {
			console.error("AuthService.js (AuthService.getProfile): " + e);
		}

        return decoded_token;
    }

	fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.isLoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
                headers,
                ...options
            })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }
}

// React library components
import React, { Component }							from 'react';
import { Button, Grid, Container, Popup, Confirm }   from 'semantic-ui-react';
import ReactTable                                   from 'react-table';

import "react-table/react-table.css";

// Custom React components
import withAuth 	 	                            from './withAuth.js';
import AuthService	 			                    from './AuthService.js';

// AgentList.js Global variables

const Auth = new AuthService();     // Instantiate AuthService

const columns = [{
    Header: 'UUID',
    accessor: 'uuid',
    show: false
},{
    Header: 'Agent Name',
    accessor: 'name'
},{
    Header: 'Status',
    accessor: 'status',
    Cell:   row => (
        <span>{
            row.value === 'Active' ? <Popup trigger={<i className="circle icon agent-on">-Active</i>} content='Actively working Agent' />
            : row.value === 'Inactive' ? <Popup trigger={<i className="circle outline icon agent-off">-Inactive</i>} content='Check the Agent services' />
            : row.value === 'Provisioned' ? <Popup trigger={<i className="circle outline icon agent-provisioned">-Provisioned</i>} content='Agent waiting installation or activation' />
            : row.value === 'Error' ? <i className="circle outline icon agent-error">-Error</i>
            : 'Unknown'
        }
        </span>
    )
},{
    Header: 'Actions',
    Cell:   row => (
        <span>
        <Popup trigger={<i className="cog icon" name="settings" />} content='Agent settings' />
        <Popup trigger={<i className="trash alternate outline icon" name="delete" />} content='delete Agent' />
        <Popup trigger={<i className="question circle outline icon" name="helpdesk" />} content='create ticket' />
        </span>
    )
}
];     // set up the columns for the agent list table

var g_debug = 0;     // determine if there should be debug output

// Class definition
export class AgentList extends Component {
	// ** Class variables **

	// ** Class constructor **
	constructor(props) {
		super(props);

        this.state = {
            isLoaded        : false,
            clientid        : this.props.clientid,
            agents          : this.getAgents(this.props.clientid),
            deleteConfirm   : false,
            agent_clicked   : "",
        }

        this.getAgents                      = this.getAgents.bind(this);
        this.handleButtonProvisionAgent     = this.handleButtonProvisionAgent.bind(this);
        this.handleTableClick               = this.handleTableClick.bind(this);
        this.handleDeleteConfirmClick       = this.handleDeleteConfirmClick.bind(this);
        this.handleDeleteCancelClick        = this.handleDeleteCancelClick.bind(this);
	}

	// ** Class functions **

	// handleButtonProvisionAgent handler
	handleButtonProvisionAgent() {
		let path = '/agent_add';
		this.props.history.push(path);
	}

    handleDeleteConfirmClick = (state, rowInfo, column, instance) => {
        console.log("INFO AgentList.js(handleDeleteConfirmClick): called");
        this.setState({ deleteConfirm: false });
        Auth.fetch('https://pcsmonapi.parishcrest.com.au/v1/deleteagent/' + this.state.clientid + '/' + this.state.agent_clicked)
                     .then(responseData => {
                        if (g_debug) {
                            console.log("INFO (AgentList.js)[handleDeleteConfirmClick]: fetch response;");
                            console.log(responseData);
                        }
                        this.setState({
                            isLoaded: true,
                            agents: responseData
                        });
                        return responseData;
                     });
        // let path = '/agent_delete/' + this.state.agent_clicked;
        // this.props.history.push(path);
    }

    handleDeleteCancelClick = () => {
        console.log("INFO AgentList.js(handleDeleteCancelClick): called");
        this.setState({ deleteConfirm: false });
    }

    handleTableClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {
                if (typeof e.target.attributes.name !== 'undefined') {
                    if ( e.target.attributes.name.value === "delete" ) {
                        if (g_debug) { console.info("(handleTableClick): found delete"); }
                        this.setState({ deleteConfirm: true,
                                        agent_clicked: rowInfo.original.uuid});
                    } else if ( e.target.attributes.name.value === "settings" ) {
                        if (g_debug) { console.log("(handleTableClick): not found delete"); }
                        let path = '/agent_settings/' + rowInfo.original.uuid;
                        this.props.history.push(path);
                    }
                } else {
                    let path = '/agent_display/' + rowInfo.original.uuid;
                    this.props.history.push(path);
                }
            }
        }
    }

    getAgents(clientid) {
        //let clientid = this.state.clientid;
        if (g_debug) { console.info("(getAgents): Retrieve this clientid=" + clientid); }

        return Auth.fetch('https://pcsmonapi.parishcrest.com.au/v2/getagentsclaims/'+ clientid)
                     .then(responseData => {
                        if (g_debug) {
                            console.log("(getAgents): fetch response;");
                            console.log(responseData);
                        }
                        this.setState({
                            isLoaded: true,
                            agents: responseData
                        });
                        return responseData;
                     });
    }

    static getDerivedStateFromProps(props, state) {
        if (g_debug) {
            console.info("(getDerivedStateFromProps): function called props=" + props.clientid + " state=" + state.clientid);
        }

        if (props.clientid !== state.clientid) {
                if (g_debug) { console.info("(getDerivedStateFromProps): do not equate"); }
                return {
                    clientid: props.clientid,
                };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clientid !== prevProps.clientid) {
            if (g_debug) {
                console.info("(componentDidUpdate): props, prevprops;");
                console.info(prevProps.clientid);
                console.info(this.props.clientid);
            }
            this.setState({
                agents: this.getAgents(this.props.clientid),
                isLoaded: false
            });
            // this.forceUpdate();
        }
    }

	// render function
	render() {
        const { agents }        = this.state;
        const clientid          = this.props.clientid;
        const { isLoaded }      = this.state;

        const handleTableClick  = this.handleTableClick;

        if (g_debug) { console.info("(render): agents =" + agents.length);console.log(agents); }
        if (g_debug) { console.info("INFO (AgentList.js)[render]: clientid =" + clientid); }
        if (g_debug) { console.info("INFO (AgentList.js)[render]: isLoaded =" + isLoaded); }

        if (agents === null){
            return(
            <div>error</div>
            );
        }

        return (
			<div>
			<form>
				<Container text>
                <br />
                    { (isLoaded === false && clientid !==0) &&
                    <div>Loading</div> }

                <Confirm open={this.state.deleteConfirm} onCancel={this.handleDeleteCancelClick} onConfirm={this.handleDeleteConfirmClick} />

                { (clientid !==0 && isLoaded === true && agents.length === 0 ) &&
                    <div  style={{minHeight:"8em"}}> No agents available. Click the '+' button to add a new agent</div>
                }

                { ( clientid === 0 && agents.length === 0 ) &&
                    <div  style={{minHeight:"8em"}}> No client is selected. Please select one from the MenuBar Client combobox</div>
                }

                { (clientid > 0 && agents.length !== 0 && isLoaded === true ) &&
                    <div>
                    <br />
                    <ReactTable
                        data={agents}
                        columns={columns}
                        getTrProps={handleTableClick}
                        defaultPageSize= {10}
                        pageSizeOptions = {[10,20]}

                    />
                <br />
                <Grid>
                    <Grid.Column floated='left' width={5}>
                        <Popup trigger={<Button data-content="Add Device" primary onClick={this.handleButtonProvisionAgent}>+</Button>} content='provision an Agent' />
                    </Grid.Column>
                    <Grid.Column floated='left' width={1}>
                    </Grid.Column>
                </Grid>
                </div>
                }
				</Container>
			</form>
			</div>
		);
	}
}

export default withAuth(AgentList);

// React library components
import React, { Component } from "react";
import { withRouter } from 'react-router';
import { NavLink, Link } from "react-router-dom";
import { Menu, Icon, Dropdown } from 'semantic-ui-react';

// Custom React components
import AuthService from './AuthService.js';

// Global variables
const g_auth   = new AuthService();
const g_debug  = 0;

// Class definition
export class AppMenu extends Component {

	constructor(props) {
		super(props);

		if (g_debug) { console.info("(constructor): client_name=" + this.props.client_name); }

		this.state = {
			activeItem: this.props.active_menu_item,
			clients: [],
			client_id: this.props.client_id,
			client_name: this.props.client_name,
			errors: [],
			};

		this.handleMenuItemClick            = this.handleMenuItemClick.bind(this);
		this.handleLogoutClick              = this.handleLogoutClick.bind(this);
        this.handleClientComboboxClick      = this.handleClientComboboxClick.bind(this);
	}

    handleClientComboboxClick = (event, data) => {
        if (g_debug) { console.info("(handleClientComboboxClick): clicking combobox with value=" + data.value); }
		this.props.handleClientChange(data.value);
		this.setState({
			client_id: data.value
		  });
    }

	handleMenuItemClick = (e, { name, to } ) => {
		if (g_debug) { console.info("(handleMenuItemClick): menuitem clicked=" + name);}
        this.setState( { activeItem: name } );

        var current_location = this.props.location.pathname;
        if (g_debug) { console.info("(handleMenuItemClick): current location=" + current_location + " requested to=" + to);}

        if (to === current_location && to === "/grid_database_new") {
            if (g_debug) { console.info("(handleMenuItemClick): current and to are equal"); }
            this.props.app.forceUpdate();
        }
		this.props.history.push('/');
	}

	handleLogoutClick = (e, { name }) => {
		if (g_debug) { console.info("(handleLogoutClick): function called"); }
		g_auth.logout();
		this.props.handleLogout();
		//this.props.history.push('/');
	}

	hasAdminRole = () => {
		if (g_auth.isLoggedIn()) {
			var profile_data = g_auth.getProfile();
			var roles;

			if ( roles != null ) {
				roles = profile_data.roles;
				if (roles.includes("admin")) {
					return 1;
				} 
			}
		}
		return 0;
	}

	getClientCount = () => {
		if (g_auth.isLoggedIn()) {
			var roles = g_auth.getProfile().roles;
			
			if (roles != null ) {
				var count = (roles.match(/:/g) || []).length;

				return (count/2);
			}
		}
		return 0;
	}

	componentDidMount() {
		// Check if the account is logged in prior to attempting to read the roles from the profile. Make sure that the roles exist. If not - exit the funtion.
		if (!g_auth.isLoggedIn() || g_auth.getProfile().roles == null) {
			if (g_debug) { console.info('(componentDidMount): function called and is not logged in or roles is null, returning'); }
			return;
		}

		if (g_debug) { console.info('(componentDidMount): function called and is logged in'); }

		// local variables
		let client_id			= 0;
		let client_name			= "";
		let clients				= [];
		let user_client_roles	= g_auth.getProfile().roles.split(',');
		let is_first			= true;

		// Populate the clients array and set the default value
		user_client_roles.forEach((client_role) => {
			var client_split = client_role.split(":");
			if (is_first) {
				clients.push({ value: client_split[1], key: client_split[1], text: client_split[2]});
				client_id 		= client_split[1];
				// Tell App that we've updated the client_id
				if (client_id === 0) {
					if (g_debug) { console.info('(componentDidMount): function called and is logged in'); }
					this.props.handleClientChange(client_id);
					client_name 	= client_split[2];

				}
				is_first = false;
			} else {
				clients.push({ value: client_split[1], key: client_split[1], text: client_split[2]});
			}
		});

		// Update the clients array, and the default selected client_id
		this.setState({
			clients: clients,
			client_id: client_id,
			client_name: client_name
		  });
	}

	render() {
		const { activeItem }    	= this.state;
		const { clients }      		= this.state;
		const { client_id }       	= this.state;
		const { client_name }   	= this.state;

		const is_logged_in       = g_auth.isLoggedIn();
		const has_admin          = this.hasAdminRole();
		const client_count       = this.getClientCount();

		if (g_debug) { console.info("(render): client_id=" + client_id + ", client_name=" + client_name + ", client_count=" + client_count); }

		if (!is_logged_in) {
			if (g_debug) { console.info("(render): g_auth.isLoggedIn=false"); }
			return (
				<div>
					<Menu inverted>
						<Menu.Item name='home' active={activeItem === 'home'} onClick={this.handleMenuItemClick} as={Link} to='/' ><Icon name="home" />Home</Menu.Item>
						<Menu.Menu position="right">
							<Menu.Item name="login" active={activeItem === 'login'} onClick={this.handleLogoutClick} as={NavLink} to='/login'>
								<Icon name="key" />Login
							</Menu.Item>
						</Menu.Menu>
					</Menu>
				</div>
				);
		}

		// is_logged_is = true
		if (g_debug) { console.info("(render): g_auth.isLoggedIn=true"); }
		return (
		<div>
			<Menu pointing inverted>
				<Menu.Item name='home' active={activeItem === 'home'} onClick={this.handleMenuItemClick} as={Link} to='/' ><Icon name="home" />Home</Menu.Item>

				<Menu.Item name='agents' active={activeItem === 'agents'} onClick={this.handleMenuItemClick} as={Link} to='/agents' ><Icon name="computer" />Agents</Menu.Item>

				<Dropdown text='Jobs' name='jobs' className='link item'>
					<Dropdown.Menu>
						<Dropdown.Item>
							<Dropdown text='Wizards'>
								<Dropdown.Menu>
									<Dropdown.Item name='jobs' active={activeItem === 'jobs'} onClick={this.handleMenuItemClick} as={NavLink} to='/database_new' custom='database'>New Database job</Dropdown.Item>
									<Dropdown.Item name='jobs' active={activeItem === 'jobs'} onClick={this.handleMenuItemClick} as={NavLink} to='/grid_database_new' custom='grid_database_new'>New Grid Database job</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				<Menu.Menu position="right">

				{(client_count > 1 ) ?
				<Dropdown
					selection
					options={clients}
					onChange={this.handleClientComboboxClick}
					search
					defaultValue={ client_id }
					text={ client_name }
					size="small"
					/> : null }
					{ has_admin ?
					<Dropdown item text='Admin' name='admin' pointing className='link item' open={this.state.open} onBlur={this.handleClose} onFocus={this.handleOpen}>
					<Dropdown.Menu>
						<Dropdown.Item active={activeItem === 'admin'} onClick={this.handleMenuItemClick} as={NavLink} to='/users' name='users'>User Management</Dropdown.Item>
						<Dropdown.Item>
							<Dropdown text='Client Management'>
								<Dropdown.Menu>
									<Dropdown.Item active={activeItem === 'admin'} onClick={this.handleMenuItemClick} as={NavLink} to='/clients' name='clients'>Clients</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Dropdown.Item>
						<Dropdown.Item>Eg item</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Header>Example header</Dropdown.Header>
						<Dropdown.Item>Ex Item</Dropdown.Item>
						<Dropdown.Item active={activeItem === 'admin'} onClick={this.handleMenuItemClick} as={NavLink} to='/debugalertsmap' name='debugalertsmap'>Debug Alerts Map</Dropdown.Item>
						<Dropdown.Item>
							<Dropdown text='g_debug'>
								<Dropdown.Menu>
									<Dropdown.Item active={activeItem === 'admin'} onClick={this.handleMenuItemClick} as={NavLink} to='/debugsample' name='debugsample'>Sample</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Dropdown.Item>
					</Dropdown.Menu>
					</Dropdown> : null}

				<Menu.Item name="logout" active={activeItem === 'logout'} onClick={this.handleLogoutClick} as={NavLink} to='/logout'>
					<Icon name="key" />Logout
				</Menu.Item>
				</Menu.Menu>
			</Menu>
		</div>			
		);
	}
}

export default withRouter(AppMenu);
